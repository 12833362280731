import consts from "@/consts"
import {getApiProps} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        usersLiteLoad: false,
        usersFullLoad: false,
        users: [],
    },
    actions: {
        fetchUsers/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUsers', time: Date.now() / 1000})

                const params = getApiProps('users', args)
                this.$api.users.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUsers', inprogress: false})
                    });
            })
        },
        fetchUsersAll({dispatch, commit}) {
            new Promise((resolve, reject) => {
                this.$api.init.getUsers()
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {

                            commit('setUsers', response.data)
                            commit('setUsersLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUsersAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUsersAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isUsersLiteLoad) {
                    return resolve(getters.getUsers.length)
                }
                dispatch('fetchUsers', {lite: true})
                    .then((data) => {
                        commit('updateUsers', data)
                        //commit('setUsersLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUsersAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUsersAllPages({dispatch, commit, getters}) {
            //dispatch('setLastCall', {name: 'fetchUsersAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUsersChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.getUsers.length) {
                        return resolve([])
                    }
                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.getUsers.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchUsers', {page, 'page-size': pageSize})
                            .then((data) => {
                                commit('updateUsers', data)
                            })
                            .catch(() => {
                                dispatch('fetchUsers', {page, 'page-size': pageSize})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setUsersFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchUsersAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUsersChanged', inprogress: false})
                })
        },
        fetchUsersChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isUsersFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchUsersChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchUsers', args)
                .then((data) => {
                    commit('updateUsers', data)
                    return dispatch('fetchUsers', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterUsers', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUsersChanged', inprogress: false})
                });
        },
        reloadUsersAll({commit, dispatch}, args) {
            commit('clearUsers')
            return dispatch('fetchUsersAllLite', args)
                .then(() => {
                    dispatch('fetchUsersAllPages', args)
                })
        },

        fetchUser({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id) {
                    return reject(false)
                }
                const params = getApiProps('users', args)
                this.$api.users.find(args.id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUser', response.data)
                            resolve(true)
                        } else {
                            console.error(response);
                            reject(false)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    });
            });
        },

        saveUser({dispatch}, user) {
            let fn = (user.id) ? 'updateUser' : 'createUser'
            return dispatch(fn, user);
        },
        createUser({commit, dispatch}, user) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('users')
                this.$api.users.create(user, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUser', response.data)
                            dispatch('fetchUsersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateUser({commit, dispatch}, user) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('users')
                this.$api.users.update(user.id, user, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUser', response.data)
                            dispatch('fetchUsersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        deleteUser({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('users')
                this.$api.users.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data?.error)) {
                            if (!response.data) commit('deleteUser', id)
                            else commit('updateUser', response.data)
                            dispatch('fetchUsersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreUser({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('users')
                this.$api.users.restore(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUser', response.data)
                            dispatch('fetchUsersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteUserPermanently({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('users')
                this.$api.users.deletePermanently(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data?.error)) {
                            commit('deleteUser', id)
                            dispatch('fetchUsersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setUsersLiteLoad(state, LiteLoad) {
            state.usersLiteLoad = state.usersLiteLoad || LiteLoad
        },
        setUsersFullLoad(state, FullLoad) {
            state.usersFullLoad = state.usersFullLoad || FullLoad
        },

        setUsers(state, nUsers) {
            nUsers = nUsers.map(u => {
                //if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return Object.freeze(u)
            })
            nUsers.sort(sortByName)
            state.users = nUsers
        },

        updateUsers(state, nUsers) {
            if (!state.users.length) {
                nUsers = nUsers.map(u => {
                    //if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return Object.freeze(u)
                })
                nUsers.sort(sortByName)
                state.users = nUsers
                return true
            }

            // console.log('updateUsers', state.users.length)
            nUsers.forEach(function (nUser) {
                let i = state.users.findIndex(u => (u.id == nUser.id))
                if (i < 0) {
                    state.users.push(Object.freeze(nUser))
                } else
                if (!state.usersFullLoad || state.users[i][changedField] != nUser[changedField]) {
                    // updateObjectByDiff(state.users[i], nUser)
                    delete nUser.id
                    state.users[i] = Object.freeze({...state.users[i], ...nUser})
                }
            })
        },
        filterUsers(state, nUsers) {
            // let Ids = state.users.map(u=> u.id)
            let nIds = nUsers.map(u => u.id)
            let removedIds = state.users.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.users.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.users.splice(i, 1)
                }
            })
        },
        updateUser(state, nUser) {
            let i = state.users.findIndex(u => (u.id == nUser.id))
            if (i < 0) {
                state.users.push(Object.freeze(nUser))
            } else
            if (!state.usersFullLoad || state.users[i][changedField] != nUser[changedField]) {
                // updateObjectByDiff(state.users[i], nUser)
                let user = {...nUser} //ToDo fix
                delete user.id
                state.users[i] = Object.freeze({...state.users[i], ...user})
            }
        },
        deleteUser(state, id) {
            let i = state.users.findIndex(u => (u.id == id))
            if (i != -1) {
                state.users.splice(i, 1)
            }
        },
        clearUsers(state) {
            state.users = []
            state.usersLiteLoad = false
            state.usersFullLoad = false
        },
    },
    getters: {
        isUsersLiteLoad(state) {
            return state.usersLiteLoad
        },
        isUsersFullLoad(state) {
            return state.usersFullLoad
        },
        getUsers(state) {
            return state.users
        },
    }
}

<template>
    <UnitInfo__sensors_template
        :class="classForValue"
        :unitId="unitId"
        :icon="icon"
        :title="$t('Unit.sensors.Name')"
        :statusClass="statusClass"
        :statusIcon="statusIcon"
        :value="_value"
    />
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
import {mapGetters} from "vuex";
import {isValueExist} from "@/lib/lib";
export default {
    inject: ['sensorsValues'],
    props:{
        unitId: [String, Number],
        showIcon: {
            type: Boolean,
            default: () => true
        },
        showByValue: {
            type: Boolean,
            default: () => false,
        }
    },
    components:{
        UnitInfo__sensors_template,
    },
    name: "UnitInfo__sensors_name",
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters([
            "getUnits"
        ]),
        value() {
            return this.getUnits.find(u => u.id == this.unitId)?.name
        },
        isValueExist() {
            return isValueExist(this.value)
        },
        _value() {
            return this.isValueExist ? this.value : '-'
        },
        classForValue() {
            return this.isValueExist ? this.$consts.classSensors.value : this.$consts.classSensors.no_value
        },
        _showByValue() {
            return this.showByValue ? this.isValueExist : true
        },
        statusClass(){
            return ''
        },
        statusIcon(){
            return ''
        },
        icon() {
            return this.showIcon ? false : false //ToDo need create icon
        }
    },
    watch: {
        value: {
            handler: function (val) {
                if(this.sensorsValues) {
                    this.sensorsValues.name = val
                }
            },
            immediate: true
        },
    },
    beforeUnmount() {
        if(this.sensorsValues) {
            delete this.sensorsValues.name
        }
    }
}
</script>

<style scoped>

</style>
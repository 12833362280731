import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";
import UnitInfo from '@/components/units/pageInfo/UnitInfo.prometheus.vue';

const unitHwTypeTransformation = {
    pro_cargo_vision: 'pro_cargo_vision',
    philips: 'pro_cargo_vision',
    //digital_matter: 'xbles',
    //kkm_mqtt: 'xbles',
    geometris: 'obd',
    suntech: 'obd',
}
const unitTypeTransformation = {
    ble_gateway: 'xbles',
}

function getUnitDefaultRouteParams(unitId) {
    let unit = store.getters.getUnits.find(u => u.id == unitId)
    localStorage.setItem(store.getters.getUserStorageKey + '.unit.active', unitId)
    let template = unitHwTypeTransformation[unit?.hw_type] || unitTypeTransformation[unit?.type] || unit?.hw_type
    let routeName = consts.routerNames?.units?.type[template]

    //if(store.getters.isUnitsFullLoad){
        if (!routeName) {
            if (unit?.video) {
                template = 'xvision'
                routeName = consts.routerNames?.units?.type?.xvision
            } /*else
            if(unit?.reefer_type) {
                template = 'xcool'
                routeName = consts.routerNames?.units?.type?.xcool
            }*/
            else {
                template = 'xtrack'
                routeName = consts.routerNames?.units?.type?.xtrack
            }
        }
    //}
    if (!unitId) routeName = consts.routerNames.units

    return {
        routeName,
        params: {
            unitId,
            template
        }
    }
}

function clearQueryFilter(query) {
    delete query.hideEvents
    return query
}

const defaultSetRoute = {
    units: (route, router) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('units', route);
            return false;
        }
        router.push({
            name: consts.routerNames.units.main,
            params: {},
            query: {}
        })
    },
    returnToParent: (route, router) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn(consts.routerNames.units.main + ' returnToParent', route);
            return false;
        }
        let name = route.meta?.parentRouteName || consts.routerNames.units.main;
        let query = clearQueryFilter(route.query)
        router.push({
            name: name,
            params: {
                ...route.params,
            },
            query
        })
    },
    unitsAll: (route, router) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('units', route);
            return false;
        }
        let query = clearQueryFilter(route.query)
        router.push({
            name: consts.routerNames.units.all.main,
            params: {},
            query
        })
    },
    unitId: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitId', route);
            return false;
        }
        if (store.getters?.unitsOnMap?.length) {
            store.commit('setUnitsShowOnMap', {
                showOnMap: false,
                unitIds: store.getters?.unitsOnMap
            })
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams?.routeName?.info || consts?.routerNames?.units?.id,
            params: {
                ...routeParams?.params,
            },
            query
        })
    },
    liteTemplate: (route, router, lite) => {
        console.warn('liteTemplate', route, router, lite)
        return false;
    },
    unitEdit: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitEdit', route);
            return false;
        }
        //let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: consts.routerNames.units.edit.main,
            params: {
                ...route.params,
                unitId,
            },
            query: {...route.query}
        })

    },
    unitInfo: (route, router, unitId, date) => {
        console.warn('unitInfo', route, router, unitId, date)
        return false;
    },
    unitReefer: (route, router, unitId) => {
        console.warn('unitReefer', route, router, unitId)
        return false;
    },
    unitStationAnalytics: (route, router, unitId) => {
        console.warn('unitStationAnalytics', route, router, unitId)
        return false;
    },
    unitGenerator: (route, router, unitId, name) => {
        console.warn('unitGenerator', route, router, unitId, name)
        return false;
    },
    unitsFilter: (route, router, filter) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitsFilter', route);
            return false
        }
        router.push({
            name: route.name,
            params: {
                ...route.params
            },
            query: {...filter}
        })
    },
    liveVideo: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('liveVideo', route);
            return false
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams.routeName?.liveVideo,
            params: {
                ...routeParams.params,
            },
            query
        })
    },
    unitSnapshots: (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
            if (this.$isDevelopment) console.warn('unitId', route);
            return false;
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: routeParams.routeName.snapshots,
            params: {
                ...routeParams.params,
                eventXid,
                objectType: 'screenshot',
            },
            query: {...route.query}
        })
    },
    visionMD: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
            if (this.$isDevelopment) console.warn('unitId', route);
            return false;
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: routeParams.routeName.visionMD.main,
            params: {
                ...routeParams.params,
            },
            query: {...route.query}
        })
    },
    event: (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('event', route);
            return false
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams.routeName.event,
            params: {
                ...routeParams.params,
                eventXid,
            },
            query
        })
    },
    'video-event': (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('video-event', route);
            return false
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams.routeName.videoEvent,
            params: {
                ...routeParams.params,
                eventXid,
            },
            query
        })
    },
    'video-request': (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('video-request', route);
            return false
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = clearQueryFilter(route.query)
        router.push({
            name: routeParams.routeName?.videoRequest,
            params: {
                ...routeParams.params,
                eventXid,
            },
            query
        })
    },
    fastGeoitem: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('fastGeoitem', route);
            return false
        }
        router.push({
            name: consts.routerNames.units.fastGeozone,
            params: {
                unitId,
            },
            query: {...route.query}
        })
    },
    track: (route, router, unitId, date = store.getters.getDateToday) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('track', route);
            return false
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: consts.routerNames.units.track.main,
            params: {
                ...routeParams.params,
                date,
            },
            query: {
                ...route.query
            }
        })
    },
    trackDate: (route, router, date = store.getters.getDateToday) => {
        console.log('trackDate', date, route)
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('trackDate', route);
            return false
        }
        let unitId = route?.params?.unitId
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: consts.routerNames.units.track.main,
            params: {
                ...routeParams.params,
                date,
            },
            query: {...route.query}
        })
    },
    trackXid: (route, router, type, trackXid, timeFrom, timeTo, trackColor) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('trackXid', route);
            return false
        }
        let unitId = route?.params?.unitId
        let routeParams = getUnitDefaultRouteParams(unitId)
        let date = route?.params?.date
        let query = {...route.query}
        delete query.hideEvents
        trackColor = trackColor ? 'track-color' : ''
        router.push({
            name: consts.routerNames.units.track.xid,
            params: {
                ...routeParams.params,
                date,
                type,
                trackXid,
                timeFrom,
                timeTo,
                trackColor,
            },
            query: {...route.query}
        })
    },
    unitTrackXid: (route, router, date, type, trackXid, timeFrom, timeTo, trackColor, filter) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('trackXid', route);
            return false
        }
        let unitId = route?.params?.unitId
        let routeParams = getUnitDefaultRouteParams(unitId)
        trackColor = trackColor ? 'track-color' : ''
        router.push({
            name: routeParams.routeName.track,
            params: {
                ...routeParams.params,
                date,
                type,
                trackXid,
                timeFrom,
                timeTo,
                trackColor,
            },
            query: {
                ...route.query,
                ...filter,
            }
        })
    },
    trackColor: (route, router, trackColor) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('trackColor', route);
            return false
        }
        trackColor = trackColor ? 'track-color' : ''
        router.push({
            name: route.name,
            params: {
                ...route?.params,
                trackColor,
            },
            query: {...route.query}
        })
    },
    unitChangePrams: (route, router, params = {}, query = {}) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitChangePrams', route);
            return false;
        }
        router.push({
            name: route.name,
            params: {
                ...route.params,
                ...params,
            },
            query: {...route.query, ...query}
        })
    },
    setQueryParams: (route, router, query) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('setQueryParams', route);
            return false;
        }
        router.push({
            name: route.name,
            params: {
                ...route.params,
            },
            query
        })
    },
    smartSearch: (route, router, filter = {}, query = {}) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('smartSearch', route);
            return false;
        }
        router.push({
            name: consts.routerNames.units.type.xvision.smartSearch.main,
            params: {
                ...route.params,
                ...filter,
            },
            query: {
                ...query
            }
        })
    },
    smartSearchRequest: (route, router, unitId, eventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('video-request', route);
            return false
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: consts.routerNames.units.type.xvision.smartSearch.videoRequest,
            params: {
                ...routeParams.params,
                eventXid,
            },
            query: {...route.query}
        })
    },
    trackEvent: (route, router, trackEventType, trackEventXid) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('trackColor', route);
            return false
        }
        router.push({
            name: consts.routerNames.units.track.event_xid,
            params: {
                ...route.params,
                trackEventType,
                trackEventXid
            },
            query: {...route.query}
        })
    },
    trackMoveOnTrack: (route, router, speed) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('trackColor', route);
            return false
        }
        let params = {...route?.params}
        if (speed) {
            params.speed = speed
        } else if (params.speed) {
            delete params.speed
        }
        router.push({
            name: route.name,
            params,
            query: {...route.query}
        })
    },
    unitsGroupEdit: (route, router, groupId) => {
        console.warn('unitsGroupEdit', route, router, groupId)
        return false;

    },
    unitCommand: (route, router, unitId) => {
        console.warn('unitCommand', route, router, unitId)
        return false;
    },
    unitsGroupCommand: (route, router, groupId) => {
        console.warn('unitsGroupCommand', route, router, groupId)
        return false;
    },
    unitsLocator: (route, router, unitId) => {
        console.warn('unitsLocator', route, router, unitId)
        return false;
    },
    unitChat: (route, router, unitId) => {
        console.warn('unitChat', route, router, unitId)
        return false;
    },
    sensors: (route, router, unitId) => {
        console.warn('sensors', route, router, unitId)
        return false;
    },
    notificationsId: (route, router, notificationType, notificationId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('notificationsId', route);
            return false
        }
        let unitId = route?.params?.unitId
        let routeParams = getUnitDefaultRouteParams(unitId)
        let date = route?.params?.date || store.getters.getDateToday
        router.push({
            name: consts.routerNames.units.info + '.notifications',
            params: {
                ...routeParams.params,
                date,
                notificationType,
                notificationId,
            },
            query: {...route.query}
        })
    },
    xblesUnitId: (route, router, unitId, tagId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if (this.$isDevelopment) console.warn('unitId', route);
            return false;
        }
        let query = clearQueryFilter(route.query)
        let name = tagId ? consts.routerNames?.units?.type?.xbles?.id?.main : consts.routerNames?.units?.type?.xbles?.info
        router.push({
            name,
            params: {
                unitId,
                template: 'xbles',
                tagId,
            },
            query
        })
    },
    obdMap: (route, router, unitId) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if(this.$isDevelopment) console.warn('obdMap', route);
            return false;
        }

        let routeParams = getUnitDefaultRouteParams(unitId)

        router.push({
            name: routeParams?.routeName.map,
            params: {
                ...routeParams?.query,
            },
            query: {...route.query}
        })
    },
    switchObdTags: (route, router, template) => {
        if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
            if(this.$isDevelopment) console.warn('obdTags', route);
            return false;
        }

        let unitId = route?.params?.unitId
        router.push({
            name: consts.routerNames?.units?.type[template]?.info,
            params: {
                unitId,
                template
            },
            query: {...route.query}
        })
    },
}

const routesUnits = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: 'units',
        name: consts.routerNames.units.main,
        meta: {
            list: 'UnitsList',
            mainRouteName: consts.routerNames.units.main,
            pageLayout: 'block-position-unit',
            showMap: true,
            weatherInMap: true,
            unitTracking: true,
            layers: consts.defaultSettingsLayers,
            setRoute: defaultSetRoute,
        },
        components: {
            default: () => import('@/views/prometheus/portal/UnitsPage.vue'),
        },
        children: [
            {
                path: ':unitId(\\d+)/:template?',
                name: consts.routerNames.units.id,
                meta: {
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    sectionInfoByRoute: true,
                    pageClass: 'prometheus-portal-units',
                    layers: {
                        ...consts.defaultSettingsLayers,
                        units: {visible: true, showInList: true, showLayer: true},
                        geoitems: {visible: false, showInList: true, showLayer: true},
                    }
                },
                props: {
                    default: function (route) {
                        return {unitId: route.params.unitId}
                    },
                },
                components: {
                    default: UnitInfo,
                },
                children: [

                            //info
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.xtrack.info,
                                meta: {
                                    mainRouteName: consts.routerNames.units.type.xtrack.info,
                                    parentRouteName: consts.routerNames.units.type.xtrack.main,
                                    pageClass: 'prometheus-portal__xtrack',//prometheus-portal__info

                                },
                                props: {
                                    default: (route) => ({unitId: route.params.unitId}),
                                    info: (route) => ({unitId: route.params.unitId}),
                                },
                                components: {
                                    default: () => import('@/components/units/info/UnitInfo_default.prometheus.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: 'event/:eventXid',
                                        name: consts.routerNames.units.type.xtrack.event,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.xtrack.info,
                                            parentRouteName: consts.routerNames.units.type.xtrack.info,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: false, showLayer: false},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                notifications: {visible: true, showInList: false, showLayer: true},
                                            }
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },
                                    {
                                        path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                        name: consts.routerNames.units.type.xtrack.track,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.xtrack.info,
                                            parentRouteName: consts.routerNames.units.type.xtrack.info,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: true, showLayer: true},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                tracks: {visible: true, showInList: false, showLayer: true},
                                            }
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },//track
                                    {
                                        path: 'sensors',
                                        name: consts.routerNames.units.type.xtrack.sensors,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.xtrack.info,
                                            parentRouteName: consts.routerNames.units.type.xtrack.info,
                                            pageClass: 'prometheus-portal__xtrack prometheus-portal-sensors',
                                        },
                                        props: {
                                            default: () => ({title: 'Sensors'})
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_test.vue'),
                                        },
                                    },//sensors
                                ]
                            }, //info xtrack
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.xvision.info,
                                meta: {
                                    mainRouteName: consts.routerNames.units.type.xvision.info,
                                    parentRouteName: consts.routerNames.units.type.xvision.main,
                                    pageClass: 'prometheus-portal__xvision',//prometheus-portal__info
                                    showSectionInfoByMeta: true,
                                    isVideo: true,
                                },
                                props: {
                                    default: (route) => ({unitId: route.params.unitId}),
                                    info: (route) => ({
                                        unitId: route.params.unitId,
                                        infoList: ['unit_info', 'notifications', 'sensors', 'driver']//, 'ext_services', 'photo'
                                    }),
                                },
                                components: {
                                    default: () => import('@/components/units/info/UnitInfo_hasVideo.prometheus.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                        name: consts.routerNames.units.type.xvision.track,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.xvision.info,
                                            parentRouteName: consts.routerNames.units.type.xvision.info,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: true, showLayer: true},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                tracks: {visible: true, showInList: false, showLayer: true},
                                            }
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },//track
                                    {
                                        path: 'event/:eventXid',
                                        name: consts.routerNames.units.type.xvision.event,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.xvision.info,
                                            parentRouteName: consts.routerNames.units.type.xvision.info,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: false, showLayer: false},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                notifications: {visible: true, showInList: false, showLayer: true},
                                            }
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },
                                    {
                                        path: 'video-event/:eventXid',
                                        name: consts.routerNames.units.type.xvision.videoEvent,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.xvision.info,
                                            parentRouteName: consts.routerNames.units.type.xvision.info,
                                            pageClass: 'prometheus-portal__xvision prometheus-portal__xvision__video',
                                            isVideo: true,
                                            weatherInMap: false,
                                            sectionInfoByRoute: false,
                                            hideInfoUnit: true,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: false, showLayer: false},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                notifications: {visible: true, showInList: false, showLayer: true},
                                            }
                                        },
                                        props: {
                                            VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                                        },
                                        components: {
                                            VideoEventComponent: () => import('@/components/video/control/video-event/VideoEvent.vue')
                                        },
                                    },
                                    {
                                        path: 'live-video',
                                        name: consts.routerNames.units.type.xvision.liveVideo,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.xvision.info,
                                            parentRouteName: consts.routerNames.units.type.xvision.info,
                                            pageClass: 'prometheus-portal__xvision prometheus-portal__xvision__video-live',
                                            isVideo: true,
                                            sectionInfoByRoute: false,
                                            hideInfoUnit: true,
                                        },
                                        props: {
                                            LiveVideoComponent: (route) => ({unitId: route.params.unitId})
                                        },
                                        components: {
                                            LiveVideoComponent: () => import('@/components/video/control/live-video/LiveVideo.vue'),
                                        }
                                    },
                                    {
                                        path: 'video-request/:eventXid',
                                        name: consts.routerNames.units.type.xvision.videoRequest,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.xvision.info,
                                            parentRouteName: consts.routerNames.units.type.xvision.info,
                                            pageClass: 'prometheus-portal__xvision prometheus-portal__xvision__video',
                                            isVideo: true,
                                            showMapInfo: false,
                                            sectionInfoByRoute: false,
                                            hideInfoUnit: true,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: false, showLayer: false},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                notifications: {visible: true, showInList: false, showLayer: true},
                                            }
                                        },
                                        props: {
                                            VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                                        },
                                        components: {
                                            VideoEventComponent: () => import('@/components/video/control/video-request/VideoRequest.vue')
                                        },
                                    },
                                    {
                                        path: 'sensors',
                                        name: consts.routerNames.units.type.xvision.sensors,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.xvision.info,
                                            parentRouteName: consts.routerNames.units.type.xvision.info,
                                            pageClass: 'prometheus-portal__xvision prometheus-portal-sensors',
                                        },
                                        props: {
                                            default: () => ({title: 'Sensors'})
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_test.vue'),
                                        },
                                    },//sensors
                                    {
                                        path: ':objectType/:eventXid',
                                        name: consts.routerNames?.units?.type?.xvision?.snapshots,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xvision?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.xvision?.info,
                                            pageClass: 'prometheus-portal__xvision prometheus-portal-snapshots',
                                            hideInfoUnit: true,
                                        },
                                        props: {
                                            default: (route) => ({
                                                unitId: route.params.unitId,
                                                eventXid: route.params.eventXid,
                                            })
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_screenWall.vue'),
                                        },
                                    },//snapshots
                                    {
                                        path: 'smart-search/:mode(time|geozone)?/:from(\\d+)?/:to(\\d+)?',
                                        name: consts.routerNames.units.type.xvision.smartSearch.main,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xvision?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.xvision?.info,
                                            pageClass: 'prometheus-portal__xvision prometheus-portal-smart-search',
                                            smartSearch: true,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: true, showLayer: true},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                smartSearch: {visible: true, showInList: false, showLayer: true},
                                            },
                                        },
                                        props: {
                                        },
                                        components: {
                                            default: () => import('@/components/units/UnitsSmartSearch.vue')
                                        },
                                        children: [
                                            {
                                                path: 'video-request/:eventXid',
                                                name: consts.routerNames.units.type.xvision.smartSearch.videoRequest,
                                                meta: {
                                                    mainRouteName: consts.routerNames.units.type.xvision.info,
                                                    parentRouteName: consts.routerNames.units.type.xvision.info,
                                                    pageClass: 'prometheus-portal__xvision prometheus-portal-smart-search prometheus-portal-smart-search__video',
                                                    isVideo: true,
                                                    showMapInfo: false,
                                                    sectionInfoByRoute: false,
                                                    hideInfoUnit: false,
                                                },
                                                props: {
                                                    default: (route) => ({eventXid: route.params.eventXid})
                                                },
                                                components: {
                                                    default: () => import('@/components/video/control/video-request/VideoRequest.vue')
                                                },
                                            },
                                        ]
                                    },
                                ]
                            },//info xvision
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.pro_cargo_vision.info,
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.info,
                                    parentRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.main,
                                    pageClass: 'prometheus-portal__pro-cargo-vision',//prometheus-portal__info
                                    isVideo: true,
                                    hideSmartSearch: true,
                                    ScreenWallComponent: 'UnitsScreenWallLast_cargo_vision',
                                    //hideInfoUnit: true,
                                },
                                props: {
                                    default: (route) => ({
                                        unitId: route.params.unitId,
                                        eventXid: route.params.eventXid,
                                        objectType: route.params.objectType,
                                    }),
                                    info: (route) => ({
                                        unitId: route.params.unitId,
                                        infoList: ['unit_info', 'notifications', 'sensors', 'driver']//, 'ext_services', 'photo'
                                    }),
                                },
                                components: {
                                    default: () => import('@/components/units/info/UnitInfo_cargo_vision.prometheus.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                        name: consts.routerNames.units.type.pro_cargo_vision.track,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.pro_cargo_vision.info,
                                            parentRouteName: consts.routerNames.units.type.pro_cargo_vision.info,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: true, showLayer: true},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                tracks: {visible: true, showInList: false, showLayer: true},
                                            }
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },//track
                                    {
                                        path: 'event/:eventXid',
                                        name: consts.routerNames.units.type.pro_cargo_vision.event,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.pro_cargo_vision.info,
                                            parentRouteName: consts.routerNames.units.type.pro_cargo_vision.info,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: false, showLayer: false},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                notifications: {visible: true, showInList: false, showLayer: true},
                                            }
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },
                                    {
                                        path: 'video-event/:eventXid',
                                        name: consts.routerNames?.units?.type?.pro_cargo_vision?.videoEvent,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.main,
                                            parentRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.info,
                                            pageClass: 'prometheus-portal__pro-cargo-vision prometheus-portal__pro-cargo-vision__video',
                                            isVideo: true,
                                            weatherInMap: false,
                                            sectionInfoByRoute: false,
                                            hideInfoUnit: true,
                                        },
                                        props: {
                                            VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                                        },
                                        components: {
                                            VideoEventComponent: () => import('@/components/video/control/video-event/VideoEvent.vue')
                                        },
                                    },
                                    {
                                        path: 'live-video',
                                        name: consts.routerNames?.units?.type?.pro_cargo_vision?.liveVideo,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.main,
                                            parentRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.info,
                                            pageClass: 'prometheus-portal__pro-cargo-vision prometheus-portal__pro-cargo-vision__video-live',
                                            isVideo: true,
                                            sectionInfoByRoute: false,
                                            hideInfoUnit: true,
                                        },
                                        props: {
                                            LiveVideoComponent: (route) => ({unitId: route.params.unitId})
                                        },
                                        components: {
                                            LiveVideoComponent: () => import('@/components/video/control/live-video/LiveVideo.vue'),
                                        }
                                    },
                                    {
                                        path: 'video-request/:eventXid',
                                        name: consts.routerNames?.units?.type?.pro_cargo_vision?.videoRequest,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.main,
                                            parentRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.info,
                                            pageClass: 'prometheus-portal__pro-cargo-vision prometheus-portal__pro-cargo-vision__video',
                                            isVideo: true,
                                            showMapInfo: false,
                                            sectionInfoByRoute: false,
                                            hideInfoUnit: true,
                                        },
                                        props: {
                                            VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                                        },
                                        components: {
                                            VideoEventComponent: () => import('@/components/video/control/video-request/VideoRequest.vue')
                                        },
                                    },
                                    {
                                        path: 'sensors',
                                        name: consts.routerNames?.units?.type?.pro_cargo_vision?.sensors,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.info,
                                            pageClass: 'prometheus-portal__pro-cargo-vision prometheus-portal-sensors',
                                        },
                                        props: {
                                            default: () => ({title: 'Sensors'})
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_test.vue'),
                                        },
                                    },//sensors
                                    {
                                        path: ':objectType/:eventXid',
                                        name: consts.routerNames?.units?.type?.pro_cargo_vision?.snapshots,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.info,
                                            pageClass: 'prometheus-portal__pro-cargo-vision prometheus-portal-snapshots',
                                            hideInfoUnit: true,
                                        },
                                        props: {
                                            default: (route) => ({
                                                unitId: route.params.unitId,
                                                eventXid: route.params.eventXid,
                                            })
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_screenWall.vue'),
                                        },
                                    },//snapshots
                                    {
                                        path: 'smart-search/:mode(time|geozone)?/:from(\\d+)?/:to(\\d+)?',
                                        name: consts.routerNames.units.type.pro_cargo_vision.smartSearch.main,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.pro_cargo_vision?.info,
                                            pageClass: 'prometheus-portal__pro-cargo-vision prometheus-portal-smart-search',
                                            smartSearch: true,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: true, showLayer: true},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                smartSearch: {visible: true, showInList: false, showLayer: true},
                                            },
                                        },
                                        props: {
                                        },
                                        components: {
                                            default: () => import('@/components/units/UnitsSmartSearch.vue')
                                        },
                                        children: [
                                            {
                                                path: 'video-request/:eventXid',
                                                name: consts.routerNames.units.type.pro_cargo_vision.smartSearch.videoRequest,
                                                meta: {
                                                    mainRouteName: consts.routerNames.units.type.pro_cargo_vision.info,
                                                    parentRouteName: consts.routerNames.units.type.pro_cargo_vision.info,
                                                    pageClass: 'prometheus-portal__pro-cargo-vision prometheus-portal-smart-search prometheus-portal-smart-search__video',
                                                    isVideo: true,
                                                    showMapInfo: false,
                                                    sectionInfoByRoute: false,
                                                    hideInfoUnit: false,
                                                },
                                                props: {
                                                    default: (route) => ({eventXid: route.params.eventXid})
                                                },
                                                components: {
                                                    default: () => import('@/components/video/control/video-request/VideoRequest.vue')
                                                },
                                            },
                                        ]
                                    },
                                ]
                            },//info pro_cargo_vision
                            {
                                path: 'info',
                                name: consts.routerNames.units.type.xbles.info,
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.type?.xbles?.info,
                                    parentRouteName: consts.routerNames?.units?.type?.xbles?.main,
                                    pageClass: 'prometheus-portal__xbles',//prometheus-portal__info
                                    isVideo: false,
                                    hideInfoUnit: false,
                                    smartSearch: false,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: true, showInList: true, showLayer: true},
                                        unit_zone: {visible: true, showInList: false, showLayer: true},
                                    },
                                    headerTabs: [
                                        {title: 'OBD', fn: (route, router) => defaultSetRoute.switchObdTags(route, router, 'obd')},
                                        {title: 'Tags', fn: (route, router) => defaultSetRoute.switchObdTags(route, router, 'xbles')},
                                    ],
                                    setRoute: {
                                        ...defaultSetRoute,
                                        unitInfo: (route, router, unitId) => {
                                            if (getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
                                                if (this.$isDevelopment) console.warn('unitInfo', route);
                                                return false;
                                            }
                                            router.push({
                                                name:  consts.routerNames?.units?.type?.xbles?.map_info?.main,
                                                params: {
                                                    ...route.params,
                                                    unitId,
                                                },
                                                query: {...route.query}
                                            })
                                        },
                                    },
                                },
                                props: {
                                    default: (route) => ({
                                        unitId: route.params.unitId,
                                    }),
                                    info: (route) => ({
                                        unitId: route.params.unitId,
                                        infoList: ['unit_info', 'notifications', 'sensors', 'driver']//, 'ext_services', 'photo'
                                    }),
                                },
                                components: {
                                    default: () => import('@/components/units/info/UnitInfo_xbles.prometheus.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: 'event/:eventXid',
                                        name: consts.routerNames.units.type.xbles.event,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.xbles.info,
                                            parentRouteName: consts.routerNames.units.type.xbles.info,
                                            unitTracking: false,
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },
                                    {
                                        path: 'map-info',
                                        name: consts.routerNames?.units?.type?.xbles?.map_info?.main,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xbles?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.xbles?.info,
                                            // pageClass: 'prometheus-portal__xbles prometheus-portal__xbles-id',
                                        },
                                        props: {
                                            default: (route) => ({
                                                unitId: route?.params?.unitId
                                            })
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_xbles_map_info.prometheus.vue'),
                                        },
                                    },//sensors
                                    {
                                        path: ':tagId',
                                        name: consts.routerNames?.units?.type?.xbles?.id?.main,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xbles?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.xbles?.info,
                                            // pageClass: 'prometheus-portal__xbles prometheus-portal__xbles-id',
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },//sensors
                                ]
                            },//info xbles

                            {
                                path: 'info',
                                name: consts.routerNames.units?.type?.obd?.info,
                                meta: {
                                    mainRouteName: consts.routerNames.units?.type?.obd?.info,
                                    parentRouteName: consts.routerNames.units?.type?.obd?.main,
                                    pageClass: 'unit-obd',
                                    isVideo: true,
                                    hideInfoUnit: false,
                                    smartSearch: false,
                                    headerTabs: [
                                        {title: 'OBD', fn: (route, router) => defaultSetRoute.switchObdTags(route, router, 'obd')},
                                        {title: 'Tags', fn: (route, router) => defaultSetRoute.switchObdTags(route, router, 'xbles')},
                                    ],
                                },
                                props: {
                                    default: (route) => ({
                                        unitId: route.params.unitId,
                                        eventXid: route.params.eventXid,
                                        objectType: route.params.objectType,
                                        tagId: route.params.tagId,
                                    }),
                                    info: (route) => ({
                                        unitId: route.params.unitId,
                                        infoList: ['unit_info', 'notifications', 'sensors', 'driver']//, 'ext_services', 'photo'
                                    }),
                                },
                                components: {
                                    default: () => import('@/components/units/info/UnitInfo_OBD.prometheus.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: ':date(\\d{4}-\\d{2}-\\d{2})?/:type(parking|driving)?/:trackXid?/:timeFrom(\\d+)?/:timeTo(\\d+)?/:trackColor(track-color)?/:speed(\\d+)?',
                                        name: consts.routerNames.units.type.obd.track,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.obd.info,
                                            parentRouteName: consts.routerNames.units.type.obd.info,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                units: {visible: false, showInList: true, showLayer: true},
                                                geoitems: {visible: false, showInList: true, showLayer: true},
                                                tracks: {visible: true, showInList: false, showLayer: true},
                                            }
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },//track
                                    {
                                        path: 'event/:eventXid',
                                        name: consts.routerNames.units.type.obd.event,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.obd.info,
                                            parentRouteName: consts.routerNames.units.type.obd.info,
                                            unitTracking: false,
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },
                                    {
                                        path: 'vision-md',
                                        name: consts.routerNames.units.type.obd.visionMD.main,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.obd.info,
                                            parentRouteName: consts.routerNames.units.type.obd.info,
                                            pageClass: 'unit-obd unit-obd__vision-md',
                                        },
                                        props: {
                                        },
                                        components: {
                                        },
                                    },
                                    {
                                        path: 'video-event/:eventXid',
                                        name: consts.routerNames.units.type.obd.videoEvent,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.obd.info,
                                            parentRouteName: consts.routerNames.units.type.obd.info,
                                            pageClass: 'unit-obd unit-obd__video',
                                            isVideo: true,
                                            sectionInfoByRoute: false,
                                            hideInfoUnit: true,
                                        },
                                        props: {
                                            VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                                        },
                                        components: {
                                            VideoEventComponent: () => import('@/components/video/control/video-event/VideoEvent.vue')
                                        },
                                    },
                                    {
                                        path: 'live-video',
                                        name: consts.routerNames.units.type.obd.liveVideo,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.obd.info,
                                            parentRouteName: consts.routerNames.units.type.obd.info,
                                            pageClass: 'unit-obd unit-obd__video',
                                            isVideo: true,
                                            sectionInfoByRoute: false,
                                            hideInfoUnit: true,
                                        },
                                        props: {
                                            LiveVideoComponent: (route) => ({unitId: route.params.unitId})
                                        },
                                        components: {
                                            LiveVideoComponent: () => import('@/components/video/control/live-video/LiveVideo.vue'),
                                        }
                                    },
                                    {
                                        path: 'video-request/:eventXid',
                                        name: consts.routerNames.units.type.obd.videoRequest,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.type.obd.info,
                                            parentRouteName: consts.routerNames.units.type.obd.info,
                                            pageClass: 'unit-obd unit-obd__video',
                                            isVideo: true,
                                            sectionInfoByRoute: false,
                                            hideInfoUnit: true,
                                        },
                                        props: {
                                            VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                                        },
                                        components: {
                                            VideoEventComponent: () => import('@/components/video/control/video-request/VideoRequest.vue')
                                        },
                                    },
                                ],
                            },//info obd

                            //track
                            {
                                path: 'track/:date(\\d{4}-\\d{2}-\\d{2})?',
                                name: consts.routerNames.units.track.main,
                                meta: {
                                    mainRouteName: consts.routerNames.units.track.main,
                                    parentRouteName: consts.routerNames.units.track.main,
                                    unitTracking: false,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: false, showInList: false, showLayer: true},
                                        geoitems: {visible: false, showInList: false, showLayer: true},
                                        tracks: {visible: true, showInList: false, showLayer: true},
                                    }
                                    //pageClass: '',

                                },
                                props: {
                                    // default: (route) => ({unitId: route.params.unitId}),
                                    info: (route) => ({unitId: route.params.unitId}),
                                },
                                components: {
                                    // default: () => import('@/components/units/info/UnitInfo_hasVideo.prometheus.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_track.vue'),
                                },
                                children: [
                                    {
                                        path: ':type(parking|driving)/:trackXid:/:timeFrom(\\d+)/:timeTo(\\d+)/:trackColor(track-color)?/:speed(\\d+)?',
                                        name: consts.routerNames.units.track.xid,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.track.main,
                                            parentRouteName: consts.routerNames.units.track.main,
                                            unitTracking: false,
                                        },
                                        children: [
                                            {
                                                path: ':trackEventType/:trackEventXid',
                                                name: consts.routerNames.units.track.event_xid,
                                                meta: {
                                                    mainRouteName: consts.routerNames.units.track.main,
                                                    parentRouteName: consts.routerNames.units.track.main,
                                                },
                                            },
                                        ]
                                    },
                                ]
                            },//track

                            {
                                path: 'edit',
                                name: consts.routerNames.units.edit.main,
                                meta: {
                                    mainRouteName: consts.routerNames.units.edit.main,
                                    parentRouteName: consts.routerNames.units.edit.main,
                                    sectionInfoByRoute: true,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: true, showInList: true, showLayer: true},
                                    },
                                },
                                props: {
                                    info: (route) => ({
                                        unitId: route.params.unitId,
                                        time: Date.now(),
                                    })
                                },
                                components: {
                                    info: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                                },
                                children: [
                                    {
                                        path: '',
                                        name: consts.routerNames?.units?.type?.xbles?.edit,
                                        meta: {
                                            mainRouteName: consts.routerNames.units.edit.main,
                                            parentRouteName: consts.routerNames.units.edit.main,
                                            sectionInfoByRoute: true,
                                            unitTracking: false,
                                            layers: {
                                                ...consts.defaultSettingsLayers,
                                                unit_edit: {visible: true, showInList: false, showLayer: true},
                                                unit_zone: {visible: true, showInList: false, showLayer: true},
                                            },
                                        },
                                        props: {
                                        },
                                        components: {
                                        }
                                    },
                                ]
                            },

                            {
                                path: 'fast-geozone',
                                name: consts.routerNames.units.fastGeozone,
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.main,
                                    sectionInfoByRoute: true,
                                    fastGeozone: true,
                                    layers: {
                                        ...consts.defaultSettingsLayers,
                                        units: {visible: true, showInList: true, showLayer: true},
                                        geoitems: {visible: false, showInList: true, showLayer: true},
                                        geoitem_edit: {visible: true, showInList: false, showLayer: true},
                                    }
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: +route.params?.unitId,
                                        time: Date.now()
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                                }
                            },

                ],
            },
        ],
        // beforeEnter: (to, from) => {
        //     if(from.name && from.name != 'Login'){// First load not correct getUserStorageKey == 0
        //         let unitsIdPage = {name: 'Units', params: {unitId: localStorage.getItem(store.getters.getUserStorageKey+'.unit.active')}}
        //         if(to.name == 'Units' && !to?.params?.unitId) {
        //             return to?.params?.unitId ? to : unitsIdPage
        //         } else{
        //             return true
        //         }
        //     } else
        //     if(to?.params?.unitId) {// First load
        //         return {...to, params: {}}
        //     }
        // },
        // Vuex version !!!
        // beforeEnter: (to) => {
        //     // let unitId = localStorage.getItem(this.getUserStorageKey + '.unit.active')*1
        //     let unitId = to.params?.unitId
        //     if (!unitId) {
        //         unitId = localStorage.getItem(store.getters.getUserStorageKey+'.unit.active')*1
        //         to.params.unitId = unitId
        //     }
        //     //return false
        // },
    },
];

export {routesUnits, getUnitDefaultRouteParams}
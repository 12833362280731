<template>
    <!--- Navigation --->
    <nav class="section__navigation navigation pfull__12">
        <div class="horizontal-line">
            <div class="filter-search filter-search_has-filter mr__8 "
                 :class="{'filter-search_active': FilterSearchValue>'','filter-search_focus': FilterSearchFocusin}"
            >
                <button class="filter-search__filter button button_view_icon button_theme_primary button_dropdown-menu"
                        @click="switchShowFilterDropdownMenu"
                >
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__slider" /></span> </span>
                </button>
                <ul class="filter-search__choice">
                    <li class="filter-search__choice-item"
                        v-show="routeQueryFilter.signal"
                        @click="setUnitsQueryFilterParam('signal',false)"
                    >
                        {{FilterSearchSignalText}}
                    </li>
                    <li class="filter-search__choice-item"
                        v-show="routeQueryFilter.movement"
                        @click="setUnitsQueryFilterParam('movement',false)"
                    >
                        {{FilterSearchMovementText}}
                    </li>
                    <li class="filter-search__choice-item"
                        v-show="routeQueryFilter.type"
                        @click="setUnitsQueryFilterParam('type',false)"
                    >
                        {{FilterSearchTypeText}}
                    </li>
                    <li class="filter-search__choice-item"
                        v-show="routeQueryFilter.camera"
                        @click="setUnitsQueryFilterParam('camera',false)"
                    >
                        {{FilterSearchCameraText}}
                    </li>
                </ul>
                <input class="filter-search__input" type="text" :placeholder="$t('text.Search')" v-model="FilterSearchValue" @focus="FilterSearchFocusin=true" @blur="FilterSearchFocusin=false">
                <button class="filter-search__close button button_view_icon button_theme_primary" @click="FilterSearchValue=''">
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close_sm" /></span> </span>
                </button>
                <div class="filter-search__dropdown-menu dropdown-menu"
                     :class="{'dropdown-menu_open': showFilterDropdownMenu}"
                >
                    <div class="dropdown-menu__wrap">
                        <ul class="dropdown-menu__list list">
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="all"
                                :class="{'item_active': isAll}"
                                @click="resetUnitsFilter"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__car" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.status.All units') }}</span>
                            </li>

                            <li class="dropdown-menu__item item item_type_separator"></li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="signal"
                                :class="{'item_active': routeQueryFilter.signal==='on'}"
                                @click="setUnitsQueryFilterParam('signal','on')"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon is_success"> <span class="icon__symbol"><SvgIcon class="icon" name="common__signal" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.signals.ON line') }}</span>
                            </li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="signal"
                                :class="{'item_active': routeQueryFilter.signal==='off'}"
                                @click="setUnitsQueryFilterParam('signal','off')"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon is_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="common__signal_lost" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.signals.OFF line') }}</span>
                            </li>

                            <li class="dropdown-menu__item item item_type_separator"></li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="movement"
                                :class="{'item_active': routeQueryFilter.movement==='moving'}"
                                @click="setUnitsQueryFilterParam('movement','moving')"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon is_success"> <span class="icon__symbol"><SvgIcon class="icon" name="common__movement" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.movements.Moving') }}</span>
                            </li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="movement"
                                :class="{'item_active': routeQueryFilter.movement==='stopped'}"
                                @click="setUnitsQueryFilterParam('movement','stopped')"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__parking" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.movements.Stopped') }}</span>
                            </li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="movement"
                                :class="{'item_active': routeQueryFilter.movement==='ignition'}"
                                @click="setUnitsQueryFilterParam('movement','ignition')"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon is_warning"> <span class="icon__symbol"><SvgIcon class="icon" name="common__ignition" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.movements.Ignition on') }}</span>
                            </li>

                            <li class="dropdown-menu__item item item_type_separator"></li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="camera"
                                v-for="camera in $consts.FilterSearchLists?.Units?.camera"
                                :key="camera.id"
                                :class="{'item_active': routeQueryFilter.camera === camera.id}"
                                @click="setUnitsQueryFilterParam('camera', camera.id)"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon is_warning"> <span class="icon__symbol"><SvgIcon class="icon" :name="camera.icon" :style="{color: camera.color}" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t(camera.translate) }}</span>
                            </li>
                            <!-- type list -->
<!--                            <template>-->
                            <li class="dropdown-menu__item item item_type_separator"></li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="type"
                                v-for="type in filterTypes"
                                :key="type.id"
                                :class="{'item_active': routeQueryFilter.type === type.id}"
                                @click="setUnitsQueryFilterParam('type', type.id)"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" :name="type.icon" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $te('Units.types.'+type.name) ? $t('Units.types.'+type.name) : type.name }} </span>
                            </li>
<!--                            </template>-->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="navigation__button-group">
                <button class="button button_view_icon button_theme_base mtb__-4" data-type="open-drop-menu">
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__more_vertical" /></span> </span>
                </button>
                <div class="navigation__dropdown-menu dropdown-menu position_right-bottom" data-type="" >
                    <div class="dropdown-menu__wrap">
                        <ul class="dropdown-menu__list">
                            <li class="dropdown-menu__item item" v-show="!isAllUnitsShowOnMap" @click="setUnitsFilteredShowOnMap({showOnMap: true})" v-if="false">
                                <span class="dropdown-menu__icon icon">
                                    <span class="icon__symbol"><SvgIcon class="icon" name="common__show" /></span>
                                </span>
                                <span class="dropdown-menu__item-name">{{ $t('menu.Show all filtered objects on map') }}</span>
                            </li>
                            <li class="dropdown-menu__item item" v-show="isAllUnitsShowOnMap" @click="setUnitsFilteredShowOnMap({showOnMap: false})" v-if="false">
                                <span class="dropdown-menu__icon icon">
                                    <span class="icon__symbol"><SvgIcon class="icon" name="common__show_disabled" /></span>
                                </span>
                                <span class="dropdown-menu__item-name">{{ $t('menu.Hide all filtered objects on map') }}</span>
                            </li>
                            <li class="dropdown-menu__item item" @click="_setUnitsShowOnMapByRoute()">
                                <span class="dropdown-menu__icon icon">
                                    <span class="icon__symbol">
                                        <SvgIcon class="icon" :name="showUnitsOnMap ? 'common__show' : 'common__show_disabled'" />
                                    </span>
                                </span>
                                <span class="dropdown-menu__item-name">{{ showUnitsOnMap ? $t('menu.Show all objects on map') : $t('menu.Hide all objects on map') }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import { mapActions, mapGetters, mapMutations} from "vuex";
    import routeMixin from "@/mixins/routeMixin";

    export default {
        name: "UnitsList_navigation",
        inject: ['filteredUnitsIds'],
        mixins: [routeMixin],
        props: [],
        components: {},
        data() {
            return {
                FilterSearchFocusin: false,
            }
        },
        computed: {
            ...mapGetters([
                "getUnits",
                "unitsOnMap",
                "showFilterDropdownMenu",
                "getAppUser",
                "getUnitOrFirstActiveUnitId",
                "getUserStorageKey",
                "getUnitsTypes",
            ]),
            isAll(){
                return !this.routeQueryFilter.signal
                    && !this.routeQueryFilter.movement
                    && !this.routeQueryFilter.type
                    && !this.routeQueryFilter.camera
            },
            filterTypes() {
                return this.getUnitsTypes?.filter(t => t.filterByTypes)
            },
            showUnitsOnMap() {
                return this.routeName != this.$consts.routerNames.units.all.info
            },
            isAllUnitsShowOnMap() {
                let ids = this.filteredUnitsIds.filter(id => {
                    return !this.unitsOnMap.includes(id)
                })
                return ids.length === 0
            },
            FilterSearchSignalText() {
                let signal = this.$consts.FilterSearchLists.Units.signals.find(s => {
                    return s.id === this.routeQueryFilter.signal
                })
                return (signal)? this.$t(signal.translate) : ''
            },
            FilterSearchMovementText() {
                let movement = this.$consts.FilterSearchLists.Units.movements.find(s => {
                    return s.id === this.routeQueryFilter.movement
                })
                return (movement)? this.$t(movement.translate) : ''
            },
            FilterSearchTypeText() {
                let type = this.getUnitsTypes?.find(s => {
                    return s.id === this.routeQueryFilter.type
                })
                return (type) ? (this.$te('Unit.type.'+type.name) ? this.$t('Unit.type.'+type.name) : type.name) : ''
            },
            FilterSearchCameraText() {
                let type = this.$consts.FilterSearchLists.Units.camera.find(s => {
                    return s.id === this.routeQueryFilter.camera
                })
                return (type)? this.$t(type.translate) : ''
            },
            FilterSearchValue: {
                get(){
                    // console.log('FilterSearchValue.get', this.filter.name)
                    return this.routeQueryFilter.name
                },
                set(value){
                    let filter = {...this.routeQueryFilter}
                    filter.name = value
                    this.setRoute.unitsFilter(this.$route, this.$router, filter)
                },
            },
        },
        methods: {
            ...mapActions([
                "setMapBoundsByUnits",
                "setUnitsShowOnMap",
            ]),
            ...mapMutations([
                "switchShowFilterDropdownMenu",
                "setShowFilterDropdownMenu",
                "setAllUnitsShowOnMap",
            ]),
            setUnitsQueryFilterParam(param, value) {
                let filter = {...this.routeQueryFilter}
                filter[param] = value
                if(!value) delete filter[param];
                this.setRoute.unitsFilter(this.$route, this.$router, filter)
            },
            resetUnitsFilter() {
                this.setRoute.unitsFilter(this.$route, this.$router, null);
            },
            setUnitsFilteredShowOnMap(args){//{showOnMap: true, unitIds: filteredUnitsIds}
                args = {...args, unitIds: this.filteredUnitsIds}
                this.setUnitsShowOnMap(args)
                if(args.showOnMap) {
                    this.setMapBoundsByUnits(args.unitIds)
                }
            },
            _setUnitsShowOnMapByRoute(){
                let unitIds = this.getUnits.filter(u => !u.parent__id).map(u => u.id)
                if(this.showUnitsOnMap) {
                    this.setRoute.unitsAll(this.$route, this.$router)
                } else {
                    let unitId = localStorage.getItem(this.getUserStorageKey+'.unit.active')
                    unitId = this.getUnitOrFirstActiveUnitId(unitId)
                    this.setRoute.unitId(this.$route, this.$router, unitId)
                    unitIds = [unitId]
                }
                this.setAllUnitsShowOnMap(unitIds)
                this.setMapBoundsByUnits(unitIds)
            }
        },
        updated() {
            //console.log(`UnitsList_navigation updated`)
        },
        mounted() {
        },
    }
</script>

<style scoped>

</style>
<template>
    <ButtonIcon_dual_status
        :button-class="'mtb__-4'"
        :disabled="disabled"
        :icon="isTracking ? 'common__tracking' : 'common__tracking_disabled'"
        :icons="['common__tracking', 'common__tracking_disabled']"
        :value="isTracking"
        @custom-click="setRouteUnit()"
    />
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import routeMixin from "@/mixins/routeMixin";

    export default {
        name: 'UnitsList_unit_menu_tracking',
        mixins: [routeMixin],
        props: {
            unitId: [String, Number],
            disabled: Boolean,
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
            ]),
            unitIdByRoute() {
                return this.routeFilter?.unitId
            },
            isTracking(){
                return this.unitIdByRoute === this.unitId
            },
        },
        methods: {
            ...mapMutations([
                "setAllUnitsShowOnMap",
            ]),
            ...mapActions([
                "setMapBoundsByUnits",
            ]),
            setRouteUnit(){
                this.setRoute.unitId(this.$route, this.$router, this.unitId)
            },
        },
    }
</script>
import {getMainParentRouteName} from "@/lib/lib";

export default {
    computed: {
        routeName() {
            return this.$route?.name
        },
        routeFilter() {
            return this.$route?.params
        },
        routeQueryFilter() {
            return this.$route?.query
        },
        routeMeta() {
            return this.$route?.meta
        },
        setRoute() {
            return this.routeMeta?.setRoute
        },
        mainRouteName() {
            return this.routeMeta?.mainRouteName
        },
        parentRouteName() {
            return this.routeMeta.parentRouteName
        },
        mainParentRouteName() {
            return getMainParentRouteName(this.$route.name)
        },
    }
}
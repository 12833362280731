export default {
    state: {},
    actions: {
        setAllGeoitemsShowOnMap({commit, getters}) {
            let ids = getters.getGeozones.map(g => g.id)
            commit('setAllGeoitemsShowOnMap', ids)
        },
    },
    mutations: {},
    getters: {},
}

// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
//const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenanceNotificationsFullLoad: false,
        maintenanceNotificationsLiteLoad: false,
        maintenanceNotifications: [],
    },
    actions: {
        fetchMaintenanceNotifications/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceNotifications', time: Date.now() / 1000})

                const params = getApiProps('maintenance_notifications', args)
                this.$api.maintenancenotifications.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceNotifications', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceNotifications', inprogress: false})
                    });
            })
        },
        fetchMaintenanceNotificationsAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceNotificationsLiteLoad) {
                    return resolve(getters.getMaintenanceNotifications.length)
                }
                this.$api.init.getMaintenanceNotifications()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_notifications) {

                            commit('setMaintenanceNotifications', response.data.maintenance_notifications)
                            commit('setMaintenanceNotificationsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenanceNotificationsAllInit', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceNotificationsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceNotificationsLiteLoad) {
                    return resolve(getters.getMaintenanceNotifications.length)
                }
                dispatch('fetchMaintenanceNotifications', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceNotifications', data)
                        commit('setMaintenanceNotificationsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenanceNotificationsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceNotificationsAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenanceNotificationsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenanceNotificationsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.getMaintenanceNotifications.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let items = getters.getMaintenanceNotifications.length
                    let pages = Math.ceil( items / pageSize)
                    if(pages > 8) {
                        pageSize = Math.ceil(items / consts.querySettings.maxPages)
                        pages = Math.ceil(items / pageSize)
                    }
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceNotifications', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceNotifications', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceNotifications', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenanceNotificationsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenanceNotificationsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenanceNotificationsChanged', inprogress: false})
                })
        },
        fetchMaintenanceNotificationsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenanceNotificationsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenanceNotificationsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceNotifications', args)
                .then((data) => {
                    commit('updateMaintenanceNotifications', data)
                    return dispatch('fetchMaintenanceNotifications', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceNotifications', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenanceNotificationsChanged', inprogress: false})
                });
        },

        saveMaintenanceNotification({dispatch}, maintenanceNotification) {
            let fn = (maintenanceNotification.id) ? 'updateMaintenanceNotification' : 'createMaintenanceNotification'
            return dispatch(fn, maintenanceNotification);
        },
        createMaintenanceNotification({commit, dispatch}, maintenanceNotification) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_notifications')
                this.$api.maintenancenotifications.create(maintenanceNotification, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceNotification', response.data)
                            dispatch('fetchMaintenanceNotificationsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceNotification({commit, dispatch}, maintenanceNotification) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_notifications')
                this.$api.maintenancenotifications.update(maintenanceNotification.id, maintenanceNotification, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceNotification', response.data)
                            dispatch('fetchMaintenanceNotificationsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenanceNotification({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_notifications')
                this.$api.maintenancenotifications.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenanceNotification', id)
                            else commit('updateMaintenanceNotification', response.data)
                            dispatch('fetchMaintenanceNotificationsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenanceNotificationsFullLoad(state, FullLoad) {
            state.maintenanceNotificationsFullLoad = state.maintenanceNotificationsFullLoad || FullLoad
        },
        setMaintenanceNotificationsLiteLoad(state, LitaLoad) {
            state.maintenanceNotificationsLiteLoad = state.maintenanceNotificationsLiteLoad || LitaLoad
        },

        setMaintenanceNotifications(state, nMaintenanceNotifications) {
            state.maintenanceNotifications = nMaintenanceNotifications
        },

        updateMaintenanceNotifications(state, nMaintenanceNotifications) {
            if (!state.maintenanceNotifications.length) {
                nMaintenanceNotifications = nMaintenanceNotifications.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceNotifications.sort(sortByName)
                state.maintenanceNotifications = nMaintenanceNotifications
                // const chunks = arraySplitIntoChunks(nMaintenanceNotifications)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceNotifications.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceNotifications.forEach(function (nMaintenanceNotification) {
                if (nMaintenanceNotification?.name) nMaintenanceNotification.name_ = nMaintenanceNotification.name.toLocaleLowerCase()
                let i = state.maintenanceNotifications.findIndex(u => (u.id == nMaintenanceNotification.id))
                if (i < 0) {
                    state.maintenanceNotifications.push(nMaintenanceNotification) //(Object.freeze(nMaintenanceNotification))
                } else
                //if (!state.maintenanceNotificationsFullLoad || state.maintenanceNotifications[i][changedField] != nMaintenanceNotification[changedField])
                {
                    updateObjectByDiff(state.maintenanceNotifications[i], nMaintenanceNotification)
                    // delete nMaintenanceNotification.id
                    // nMaintenanceNotification = {...state.maintenanceNotifications[i], ...nMaintenanceNotification}
                    // state.maintenanceNotifications[i] = nMaintenanceNotification //Object.freeze(nMaintenanceNotification)
                }
            })

        },
        filterMaintenanceNotifications(state, nMaintenanceNotifications) {
            // let Ids = state.maintenanceNotifications.map(u=> u.id)
            let nIds = nMaintenanceNotifications.map(u => u.id)
            let removedIds = state.maintenanceNotifications.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceNotifications.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceNotifications.splice(i, 1)
                }
            })
        },
        updateMaintenanceNotification(state, nMaintenanceNotification) {
            if (nMaintenanceNotification?.name) nMaintenanceNotification.name_ = nMaintenanceNotification.name.toLocaleLowerCase()
            let i = state.maintenanceNotifications.findIndex(u => (u.id == nMaintenanceNotification.id))
            if (i < 0) {
                state.maintenanceNotifications.push(nMaintenanceNotification) //(Object.freeze(nMaintenanceNotification))
            } else
            //if (!state.maintenanceNotificationsFullLoad || state.maintenanceNotifications[i][changedField] != nMaintenanceNotification[changedField])
            {
                updateObjectByDiff(state.maintenanceNotifications[i], nMaintenanceNotification)
                // delete nMaintenanceNotification.id
                // nMaintenanceNotification = {...state.maintenanceNotifications[i], ...nMaintenanceNotification}
                // state.maintenanceNotifications[i] = nMaintenanceNotification //Object.freeze(nMaintenanceNotification)
            }
        },
        deleteMaintenanceNotification(state, id) {
            let i = state.maintenanceNotifications.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceNotifications.splice(i, 1)
            }
        },

        clearMaintenanceNotifications(state) {
            state.maintenanceNotifications = []
            state.maintenanceNotificationsFullLoad = false
        },
    },
    getters: {
        isMaintenanceNotificationsFullLoad(state) {
            return state.maintenanceNotificationsFullLoad
        },
        isMaintenanceNotificationsLiteLoad(state) {
            return state.maintenanceNotificationsLiteLoad
        },
        getMaintenanceNotifications(state) {
            return state.maintenanceNotifications
        },
        sortedMaintenanceNotificationsIds(state) {
            let maintenanceNotifications = state.maintenanceNotifications
            maintenanceNotifications.sort(sortByName)
            return maintenanceNotifications.map(u => u.id)
        },
    }
}

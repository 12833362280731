<template>
    <UnitInfo__sensors_template
        v-for="input in inputs"
        :key="input.index"
        :unitId="unitId"
        :icon="input.icon"
        :title="input.title"
        :statusClass="input.statusClass"
        :statusIcon="input.statusIcon"
        :icon-container-class="['input-sensor-container', input.iconContainerClass]"
        :value="$t(input.value || '-')"
        :valueText="input.valueText"
    />
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
import {mapGetters} from "vuex";


export default {
    inject: ['sensorsValues'],
    props:{
        unitId: [String, Number],
        showIcon: {
            type: Boolean,
            default: () => true
        },
    },
    components:{
        UnitInfo__sensors_template,
    },
    name: "UnitInfo__sensors_inputs",
    data() {
        return {
            title: this.$i18n.t('Unit.Battery'),
            icon: 'unit_dashboard__battery',
            optionsByValue:{
                'on': {
                    icon: 'unit_dashboard__check',
                    iconContainerClass: 'secondary',
                    value: 'On',
                    statusClass: '',//unit_dashboard__check_danger
                    statusIcon: '',//danger
                },
                'off':{
                    icon: 'unit_dashboard__check_danger',
                    iconContainerClass: 'danger',
                    value: 'Off',
                    statusClass: '',//unit_dashboard__check_danger
                    statusIcon: '',//danger
                },
                'null':{
                    icon: 'unit_dashboard__check_null',
                    iconContainerClass: 'gray',
                    value: '',
                    statusClass: '',//unit_dashboard__check_null
                    statusIcon: '',//gray
                }
            }
        }
    },
    computed:{
        ...mapGetters([
            "getUnits",
            "getUnitsInputsById",
        ]),
        unit() {
            return this.getUnits.find(u => u.id == this.unitId) || {}
        },
        inputs() {
            let inputs = this.getUnitsInputsById[this.unitId] || []
            return inputs.map((inp, i) => {
                let options = this.optionsByValue[inp.value];
                return {
                    index: i,
                    icon: this.showIcon ? options.icon : false,
                    iconContainerClass: options.iconContainerClass,
                    title: this.$te('input.types.' + inp.name) ? this.$t('input.types.' + inp.name) : inp.name,//this.$t('Unit.sensors.Input') + (i+1)
                    statusClass: options.statusClass,
                    statusIcon: options.statusIcon,
                    value: options.value,
                    valueText: '',//inp.name,
                    view: inp.view,
                }
            }).filter(inp => inp.view)
        },
    },
    watch: {
        inputs: {
            handler: function (val) {
                if(this.sensorsValues) {
                    let values = val.filter(v => v.value)
                    this.sensorsValues.inputs = values.length ? values : null
                }
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
    },
    beforeUnmount() {
        if(this.sensorsValues) {
            delete this.sensorsValues.inputs
        }
    }
}
</script>

<style scoped>

</style>
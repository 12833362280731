<template>
    <UnitInfo__sensors_template
        :class="classForValue"
        :unitId="unitId"
        :icon="icon"
        :title="title"
        :statusClass="statusClass"
        :statusIcon="statusIcon"
        :value="_value"
        v-if="_showByValue"
    />
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
import {mapGetters} from "vuex";
import {isValueExist} from "@/lib/lib";
export default {
    inject: ['sensorsValues'],
    props:{
        unitId: [String, Number],
        sensor: String,
        showIcon: {
            type: Boolean,
            default: () => true
        },
        showByValue: {
            type: Boolean,
            default: () => false,
        }
    },
    components:{
        UnitInfo__sensors_template,
    },
    name: "UnitInfo__sensors_default",
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters([
            "getUnitsLmsgs",
        ]),
        title() {
            if(this.$te('Unit.sensors.' + this.sensor)) {
                return  this.$t('Unit.sensors.' + this.sensor)
            }
            let text = this.sensor.replaceAll('_', ' ')
            return text[0].toUpperCase() + text.slice(1)
        },
        unitLmsg() {
            return this.getUnitsLmsgs.find(l => l.object_id == this.unitId)
        },
        unitLmsgSensors() {
            return this.unitLmsg?.sensors || {}
        },
        value() {
            return this.unitLmsgSensors[this.sensor]?.text//this.$t('Not defined')
        },
        isValueExist() {
            return isValueExist(this.value)
        },
        _value() {
            return this.isValueExist ? this.value : '-'
        },
        classForValue() {
            return this.isValueExist ? this.$consts.classSensors.value : this.$consts.classSensors.no_value
        },
        _showByValue() {
            return this.showByValue ? this.isValueExist : true
        },
        statusClass(){
            return ''
        },
        statusIcon(){
            return ''
        },
        icon() {
            return this.showIcon ? ('empty') : false
        }
    },
    watch: {
        value: {
            handler: function (val) {
                if(this.sensorsValues) {
                    this.sensorsValues[this.sensor] = val
                }
            },
            immediate: true
        },
    },
    mounted() {
    },
    beforeUnmount() {
        if(this.sensorsValues) {
            delete this.sensorsValues[this.sensor]
        }
    }
}
</script>

<style scoped>

</style>
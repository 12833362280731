<template>
    <section class="section-info-wrap section_info-position" v-if="!showMainList">
        <div class="section__info-content">
            <slot></slot>
            <div class="control-section-info-close" @click="toggleLightView" v-if="showControl">
                <MovingArrow class="unit-info-control"/>
            </div>
        </div>
        <div class="control-section-info control-section-info-2" @click="toggleLightView" v-if="showControl">
            <MovingArrow class="unit-info-control"/>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import MovingArrow from '@/components/_base/moving-arrow/MovingArrow'
import routeMixin from "@/mixins/routeMixin";

export default {
    name: "sectionMapInfoWrap",
    mixins: [routeMixin],
    props:{
        'infoHide': String,
        default: () => { return false },
        showControl: {
            type: Boolean,
            default: () => true
        }
    },
    components:{
        MovingArrow
    },
    data(){
        return{
            hideMenu: false
        }
    },
    computed:{
        ...mapGetters([
            //"getShowMapInfo",
        ]),
        isLite() {
            let routeName = this.routeName.split('.')
            return routeName[routeName.length - 1] === 'lite'
        },
        showMainList() {
            return !!this.routeFilter.noList
        },
    },
    methods:{
        ...mapMutations([
        ]),
        toggleLightView() {
            let noList = this.routeFilter.noList
            if(!noList) {
                this.setRoute.liteTemplate(this.$route, this.$router, !this.isLite)
            }
        }
    },
}
</script>

<style scoped>
.control-section-info{
    width: 50px;
    height: 50px;
    background: var(--color-primary-450);
    /*background: white;*/
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
    opacity: 0;
    visibility: hidden;
    transition: 1s;
/*  rotate(180deg) */
}
.control-section-info-2{
    background: white;
    transform: rotate(180deg);
    bottom: -45px;
    color: var(--color-font-lighten);
    padding-bottom: 7px;
}
.section__info-content{
    position: relative;
    top: 0;
    transition: .5s;
}
.unit-lite .section__info-content,
.track-lite .section__info-content,
.notifications-lite .section__info-content,
.geozone-lite .section__info-content{
    opacity: 0;
    visibility: hidden;
    transition: 1s;
    top: 200px
    /*transform: scale(-1);*/
}
.unit-lite .control-section-info,
.track-lite .control-section-info,
.notifications-lite .control-section-info,
.geozone-lite .control-section-info{
    opacity: .9;
    visibility: visible;
    transition: 1s;
}
.control-section-info-close{
    position: absolute;
    top: calc(100% - (24px/2));
    left: 50%;
    transform: translateX(-50%);
    background: white;
    width: 45px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 100%;
    cursor: pointer;
    color: var(--color-font-lighten);
}
</style>
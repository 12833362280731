// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        tiveShipmentTemplatesFullLoad: false,
        tiveShipmentTemplatesLiteLoad: false,
        tiveShipmentTemplates: [],
    },
    actions: {
        fetchTiveShipmentTemplates/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveShipmentTemplates', time: Date.now() / 1000})

                const params = getApiProps('tiveShipmentTemplates', args)
                this.$api.tiveshipmenttemplates.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveShipmentTemplates', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setTiveShipmentTemplatesLiteLoad', true)
                        commit('settiveShipmentTemplatesFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchTiveShipmentTemplates', inprogress: false})
                    });
            })
        },

        saveTiveShipmentTemplate({dispatch}, shipmentTemplate) {
            let fn = (shipmentTemplate.id) ? 'updateTiveShipmentTemplate' : 'createTiveShipmentTemplate'
            return dispatch(fn, shipmentTemplate);
        },
        createTiveShipmentTemplate({commit}, shipmentTemplate) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveShipmentTemplates')
                this.$api.tiveshipmenttemplates.create(shipmentTemplate, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveShipmentTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateTiveShipmentTemplate({commit}, shipmentTemplate) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveShipmentTemplates')
                this.$api.tiveshipmenttemplates.update(shipmentTemplate.id, shipmentTemplate, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveShipmentTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteTiveShipmentTemplate({commit}, shipmentTemplateId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveShipmentTemplates')
                this.$api.tiveshipmenttemplates.delete(shipmentTemplateId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (!response.data || !response.data.error) {
                            if (!response.data) commit('deleteTiveShipmentTemplate', shipmentTemplateId)
                            else commit('updateTiveShipmentTemplate', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        settiveShipmentTemplatesFullLoad(state, FullLoad) {
            state.tiveShipmentTemplatesFullLoad = state.tiveShipmentTemplatesFullLoad || FullLoad
        },
        setTiveShipmentTemplatesLiteLoad(state, LitaLoad) {
            state.tiveShipmentTemplatesLiteLoad = state.tiveShipmentTemplatesLiteLoad || LitaLoad
        },

        setTiveShipmentTemplates(state, nTiveShipmentTemplates) {
            nTiveShipmentTemplates = nTiveShipmentTemplates.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nTiveShipmentTemplates.sort(sortByName)
            state.tiveShipmentTemplates = nTiveShipmentTemplates
        },

        updateTiveShipmentTemplates(state, nTiveShipmentTemplates) {
            if (!state.tiveShipmentTemplates.length) {
                nTiveShipmentTemplates = nTiveShipmentTemplates.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveShipmentTemplates.sort(sortByName)
                state.tiveShipmentTemplates = nTiveShipmentTemplates
                // const chunks = arraySplitIntoChunks(nTiveShipmentTemplates)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveShipmentTemplates.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveShipmentTemplates.forEach(function (nShipmentTemplate) {
                if (nShipmentTemplate?.name) nShipmentTemplate.name_ = nShipmentTemplate.name.toLocaleLowerCase()
                let i = state.tiveShipmentTemplates.findIndex(u => (u.id == nShipmentTemplate.id))
                if (i < 0) {
                    state.tiveShipmentTemplates.push(nShipmentTemplate) //(Object.freeze(nShipmentTemplate))
                } else
                if (!state.tiveShipmentTemplatesFullLoad || state.tiveShipmentTemplates[i][changedField] != nShipmentTemplate[changedField]) {
                    updateObjectByDiff(state.tiveShipmentTemplates[i], nShipmentTemplate)
                    // delete nShipmentTemplate.shipmentTemplateId
                    // nShipmentTemplate = {...state.tiveShipmentTemplates[i], ...nShipmentTemplate}
                    // state.tiveShipmentTemplates[i] = nShipmentTemplate //Object.freeze(nShipmentTemplate)
                }
            })

        },
        filterTiveShipmentTemplates(state, nTiveShipmentTemplates) {
            // let Ids = state.tiveShipmentTemplates.map(u=> u.shipmentTemplateId)
            let nIds = nTiveShipmentTemplates.map(u => u.id)
            let removedIds = state.tiveShipmentTemplates.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.tiveShipmentTemplates.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.tiveShipmentTemplates.splice(i, 1)
                }
            })
        },
        updateTiveShipmentTemplate(state, nShipmentTemplate) {
            if (nShipmentTemplate?.name) nShipmentTemplate.name_ = nShipmentTemplate.name.toLocaleLowerCase()
            let i = state.tiveShipmentTemplates.findIndex(u => (u.id == nShipmentTemplate.id))
            if (i < 0) {
                state.tiveShipmentTemplates.push(nShipmentTemplate) //(Object.freeze(nShipmentTemplate))
            } else
            //if (!state.tiveShipmentTemplatesFullLoad || state.tiveShipmentTemplates[i][changedField] != nShipmentTemplate[changedField])
            {
                updateObjectByDiff(state.tiveShipmentTemplates[i], nShipmentTemplate)
                // delete nShipmentTemplate.shipmentTemplateId
                // nShipmentTemplate = {...state.tiveShipmentTemplates[i], ...nShipmentTemplate}
                // state.tiveShipmentTemplates[i] = nShipmentTemplate //Object.freeze(nShipmentTemplate)
            }
        },
        deleteTiveShipmentTemplate(state, shipmentTemplateId) {
            let i = state.tiveShipmentTemplates.findIndex(u => (u.id == shipmentTemplateId))
            if (i != -1) {
                state.tiveShipmentTemplates.splice(i, 1)
            }
        },

        clearTiveShipmentTemplates(state) {
            state.tiveShipmentTemplates = []
            state.tiveShipmentTemplatesFullLoad = false
        },
    },
    getters: {
        isTiveShipmentTemplatesFullLoad(state) {
            return state.tiveShipmentTemplatesFullLoad
        },
        isTiveShipmentTemplatesLiteLoad(state) {
            return state.tiveShipmentTemplatesLiteLoad
        },
        getTiveShipmentTemplates(state) {
            return state.tiveShipmentTemplates
        },
        sortedTiveShipmentTemplatesIds(state) {
            let tiveShipmentTemplates = state.tiveShipmentTemplates
            tiveShipmentTemplates.sort(sortByName)
            return tiveShipmentTemplates.map(u => u.id)
        },
    }
}

<template>
    <div class="list__symbol symbol">
        <div class="symbol__image-box" :style="{color: unitIconColor}">
            <SvgIcon style="height: 100%; width: 100%" :name="unitIcon" class="icon svg-icon-unit-marker" />
        </div>
        <template v-for="(indicator) in indicators" :key="indicator">
            <component :is="`UnitsList_unit_${indicator}_status`"
                       :unit-id="unitId"
            />
        </template>
    </div>
</template>

<script>
import UnitsList_unit_moving_status from "@/components/units/UnitsList_unit_moving_status.prometheus.vue";
import UnitsList_unit_connection_status from "@/components/units/UnitsList_unit_connection_status.prometheus.vue";
import UnitsList_unit_ignition_status from "@/components/units/UnitsList_unit_ignition_status.prometheus.vue";
import UnitsList_unit_camera_status from "@/components/units/UnitsList_unit_camera_status.prometheus.vue";

import {mapGetters} from "vuex";

export default {
    name: "UnitsList_unit_icons",
    props: {
        unitId: [String, Number],
        defaultIcon: {
            type: String,
            default: () => 'car'
        },
        indicators: {
            type: [Array, Object],
            default: () => {
                return ['connection', 'moving', 'ignition'];
            }
        }
    },
    components: {
        UnitsList_unit_moving_status,
        UnitsList_unit_connection_status,
        UnitsList_unit_ignition_status,
        UnitsList_unit_camera_status,
    },
    computed:{
        ...mapGetters([
            "getUnits",
        ]),
        unit(){
            return this.getUnits.find(u => u.id == this.unitId)
        },
        unitIcon(){
            return this.defaultIcon //ToDo fix
        },
        unitIconColor(){
            return this.unit?.icon_color || '#003B79'
        },
    }
}
</script>

<style scoped>

</style>
// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenanceContactGroupsFullLoad: false,
        maintenanceContactGroupsLiteLoad: false,
        maintenanceContactGroups: [],
    },
    actions: {
        fetchMaintenanceContactGroups/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceContactGroups', time: Date.now() / 1000})

                const params = getApiProps('maintenance_contact_groups', args)
                this.$api.maintenancecontactgroups.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceContactGroups', inprogress: false})
                    });
            })
        },
        fetchMaintenanceContactGroupsAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceContactGroupsLiteLoad) {
                    return resolve(getters.getMaintenanceContactGroups.length)
                }
                this.$api.init.getMaintenanceContactGroups()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_contact_groups) {

                            commit('setMaintenanceContactGroups', response.data.maintenance_contact_groups)
                            commit('setMaintenanceContactGroupsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenanceContactGroupsAllInit', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceContactGroupsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceContactGroupsLiteLoad) {
                    return resolve(getters.getMaintenanceContactGroups.length)
                }
                dispatch('fetchMaintenanceContactGroups', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceContactGroups', data)
                        commit('setMaintenanceContactGroupsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenanceContactGroupsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceContactGroupsAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenanceContactGroupsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenanceContactGroupsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.getMaintenanceContactGroups.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let items = getters.getMaintenanceContactGroups.length
                    let pages = Math.ceil( items / pageSize)
                    if(pages > 8) {
                        pageSize = Math.ceil(items / consts.querySettings.maxPages)
                        pages = Math.ceil(items / pageSize)
                    }
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceContactGroups', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceContactGroups', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceContactGroups', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenanceContactGroupsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenanceContactGroupsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenanceContactGroupsChanged', inprogress: false})
                })
        },
        fetchMaintenanceContactGroupsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenanceContactGroupsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenanceContactGroupsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceContactGroups', args)
                .then((data) => {
                    commit('updateMaintenanceContactGroups', data)
                    return dispatch('fetchMaintenanceContactGroups', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceContactGroups', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenanceContactGroupsChanged', inprogress: false})
                });
        },

        saveMaintenanceContactGroup({dispatch}, maintenanceContactGroup) {
            let fn = (maintenanceContactGroup.id) ? 'updateMaintenanceContactGroup' : 'createMaintenanceContactGroup'
            return dispatch(fn, maintenanceContactGroup);
        },
        createMaintenanceContactGroup({commit, dispatch}, maintenanceContactGroup) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_contact_groups')
                this.$api.maintenancecontactgroups.create(maintenanceContactGroup, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceContactGroup', response.data)
                            dispatch('fetchMaintenanceContactGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceContactGroup({commit, dispatch}, maintenanceContactGroup) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_contact_groups')
                this.$api.maintenancecontactgroups.update(maintenanceContactGroup.id, maintenanceContactGroup, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceContactGroup', response.data)
                            dispatch('fetchMaintenanceContactGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenanceContactGroup({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_contact_groups')
                this.$api.maintenancecontactgroups.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenanceContactGroup', id)
                            else commit('updateMaintenanceContactGroup', response.data)
                            dispatch('fetchMaintenanceContactGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenanceContactGroupsFullLoad(state, FullLoad) {
            state.maintenanceContactGroupsFullLoad = state.maintenanceContactGroupsFullLoad || FullLoad
        },
        setMaintenanceContactGroupsLiteLoad(state, LitaLoad) {
            state.maintenanceContactGroupsLiteLoad = state.maintenanceContactGroupsLiteLoad || LitaLoad
        },

        setMaintenanceContactGroups(state, nMaintenanceContactGroups) {
            state.maintenanceContactGroups = nMaintenanceContactGroups
        },

        updateMaintenanceContactGroups(state, nMaintenanceContactGroups) {
            if (!state.maintenanceContactGroups.length) {
                nMaintenanceContactGroups = nMaintenanceContactGroups.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceContactGroups.sort(sortByName)
                state.maintenanceContactGroups = nMaintenanceContactGroups
                // const chunks = arraySplitIntoChunks(nMaintenanceContactGroups)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceContactGroups.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceContactGroups.forEach(function (nMaintenanceContactGroup) {
                if (nMaintenanceContactGroup?.name) nMaintenanceContactGroup.name_ = nMaintenanceContactGroup.name.toLocaleLowerCase()
                let i = state.maintenanceContactGroups.findIndex(u => (u.id == nMaintenanceContactGroup.id))
                if (i < 0) {
                    state.maintenanceContactGroups.push(nMaintenanceContactGroup) //(Object.freeze(nMaintenanceContact))
                } else
                if (!state.maintenanceContactGroupsFullLoad || state.maintenanceContactGroups[i][changedField] != nMaintenanceContactGroup[changedField]) {
                    updateObjectByDiff(state.maintenanceContactGroups[i], nMaintenanceContactGroup)
                    // delete nMaintenanceContactGroup.id
                    // nMaintenanceContactGroup = {...state.maintenanceContactGroups[i], ...nMaintenanceContactGroup}
                    // state.maintenanceContactGroups[i] = nMaintenanceContactGroup //Object.freeze(nMaintenanceContactGroup)
                }
            })

        },
        filterMaintenanceContactGroups(state, nMaintenanceContactGroups) {
            // let Ids = state.maintenanceContactGroups.map(u=> u.id)
            let nIds = nMaintenanceContactGroups.map(u => u.id)
            let removedIds = state.maintenanceContactGroups.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceContactGroups.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceContactGroups.splice(i, 1)
                }
            })
        },
        updateMaintenanceContactGroup(state, nMaintenanceContactGroup) {
            if (nMaintenanceContactGroup?.name) nMaintenanceContactGroup.name_ = nMaintenanceContactGroup.name.toLocaleLowerCase()
            let i = state.maintenanceContactGroups.findIndex(u => (u.id == nMaintenanceContactGroup.id))
            if (i < 0) {
                state.maintenanceContactGroups.push(nMaintenanceContactGroup) //(Object.freeze(nMaintenanceContactGroup))
            } else
            if (!state.maintenanceContactGroupsFullLoad || state.maintenanceContactGroups[i][changedField] != nMaintenanceContactGroup[changedField]) {
                updateObjectByDiff(state.maintenanceContactGroups[i], nMaintenanceContactGroup)
                // delete nMaintenanceContactGroup.id
                // nMaintenanceContactGroup = {...state.maintenanceContactGroups[i], ...nMaintenanceContactGroup}
                // state.maintenanceContactGroups[i] = nMaintenanceContactGroup //Object.freeze(nMaintenanceContactGroup)
            }
        },
        deleteMaintenanceContactGroup(state, id) {
            let i = state.maintenanceContactGroups.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceContactGroups.splice(i, 1)
            }
        },

        clearMaintenanceContactGroups(state) {
            state.maintenanceContactGroups = []
            state.maintenanceContactGroupsFullLoad = false
        },
    },
    getters: {
        isMaintenanceContactGroupsFullLoad(state) {
            return state.maintenanceContactGroupsFullLoad
        },
        isMaintenanceContactGroupsLiteLoad(state) {
            return state.maintenanceContactGroupsLiteLoad
        },
        getMaintenanceContactGroups(state) {
            return state.maintenanceContactGroups
        },
        sortedMaintenanceContactGroupsIds(state) {
            let maintenanceContactGroups = state.maintenanceContactGroups
            maintenanceContactGroups.sort(sortByName)
            return maintenanceContactGroups.map(u => u.id)
        },
    }
}

// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = {'force[with_removed]': 1}//String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenanceInspectionsFullLoad: false,
        maintenanceInspectionsLiteLoad: false,
        maintenanceInspectionForms: [],
        maintenanceInspections: [],
    },
    actions: {
        fetchMaintenanceInspections/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceInspections', time: Date.now() / 1000})

                const params = getApiProps('maintenance_inspections', args)
                this.$api.maintenanceinspections.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceInspections', inprogress: false})
                    });
            })
        },
        fetchMaintenanceInspectionsAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceInspectionsLiteLoad) {
                    return resolve(getters.getMaintenanceInspections.length)
                }
                this.$api.init.getMaintenanceInspections()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_inspections) {

                            commit('setMaintenanceInspections', response.data.maintenance_inspections)
                            commit('setMaintenanceInspectionsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenanceInspectionsAllInit', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceInspectionsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceInspectionsLiteLoad) {
                    return resolve(getters.getMaintenanceInspections.length)
                }
                dispatch('fetchMaintenanceInspections', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceInspections', data)
                        commit('setMaintenanceInspectionsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenanceInspectionsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceInspectionsAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenanceInspectionsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenanceInspectionsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.getMaintenanceInspections.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let items = getters.getMaintenanceInspections.length
                    let pages = Math.ceil( items / pageSize)
                    if(pages > 8) {
                        pageSize = Math.ceil(items / consts.querySettings.maxPages)
                        pages = Math.ceil(items / pageSize)
                    }
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceInspections', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceInspections', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceInspections', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenanceInspectionsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenanceInspectionsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenanceInspectionsChanged', inprogress: false})
                })
        },
        fetchMaintenanceInspectionsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenanceInspectionsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenanceInspectionsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceInspections', args)
                .then((data) => {
                    commit('updateMaintenanceInspections', data)
                    return dispatch('fetchMaintenanceInspections', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceInspections', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenanceInspectionsChanged', inprogress: false})
                });
        },

        fetchMaintenanceInspectionForms({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceInspectionForms', time: Date.now() / 1000})

                const params = getApiProps('maintenance_inspection_forms', args)
                this.$api.maintenanceinspectionforms.getAll({...params, ...with_removed})
                    .then((response) => {
                        commit('updateMaintenanceInspectionForms', response.data)
                        resolve(true)
                    })
                    .catch((error) => {
                        reject(false)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceInspectionForms', inprogress: false})
                    });
            })
        },
        saveMaintenanceInspection({dispatch}, maintenanceInspection) {
            let fn = (maintenanceInspection.id) ? 'updateMaintenanceInspection' : 'createMaintenanceInspection'
            return dispatch(fn, maintenanceInspection);
        },
        createMaintenanceInspection({commit, dispatch}, maintenanceInspection) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_inspections')
                this.$api.maintenanceinspections.create(maintenanceInspection, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceInspection', response.data)
                            dispatch('fetchMaintenanceInspectionsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceInspection({commit, dispatch}, maintenanceInspection) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_inspections')
                this.$api.maintenanceinspections.update(maintenanceInspection.id, maintenanceInspection, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceInspection', response.data)
                            dispatch('fetchMaintenanceInspectionsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenanceInspection({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_inspections')
                this.$api.maintenanceinspections.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenanceInspection', id)
                            else commit('updateMaintenanceInspection', response.data)
                            dispatch('fetchMaintenanceInspectionsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        removedMaintenanceInspection({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_inspections')
                this.$api.maintenanceinspections.removed(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceInspection', response.data)
                            dispatch('fetchMaintenanceInspectionsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreMaintenanceInspection({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_inspections')
                this.$api.maintenanceinspections.restore(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceInspection', response.data)
                            dispatch('fetchMaintenanceInspectionsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenanceInspectionsFullLoad(state, FullLoad) {
            state.maintenanceInspectionsFullLoad = state.maintenanceInspectionsFullLoad || FullLoad
        },
        setMaintenanceInspectionsLiteLoad(state, LitaLoad) {
            state.maintenanceInspectionsLiteLoad = state.maintenanceInspectionsLiteLoad || LitaLoad
        },

        setMaintenanceInspections(state, nMaintenanceInspections) {
            state.maintenanceInspections = nMaintenanceInspections
        },

        updateMaintenanceInspections(state, nMaintenanceInspections) {
            if (!state.maintenanceInspections.length) {
                nMaintenanceInspections = nMaintenanceInspections.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceInspections.sort(sortByName)
                state.maintenanceInspections = nMaintenanceInspections
                // const chunks = arraySplitIntoChunks(nMaintenanceInspections)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceInspections.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceInspections.forEach(function (nMaintenanceInspection) {
                if (nMaintenanceInspection?.name) nMaintenanceInspection.name_ = nMaintenanceInspection.name.toLocaleLowerCase()
                let i = state.maintenanceInspections.findIndex(u => (u.id == nMaintenanceInspection.id))
                if (i < 0) {
                    state.maintenanceInspections.push(nMaintenanceInspection) //(Object.freeze(nMaintenanceInspection))
                } else
                if (!state.maintenanceInspectionsFullLoad || state.maintenanceInspections[i][changedField] != nMaintenanceInspection[changedField]) {
                    updateObjectByDiff(state.maintenanceInspections[i], nMaintenanceInspection)
                    // delete nMaintenanceInspection.id
                    // nMaintenanceInspection = {...state.maintenanceInspections[i], ...nMaintenanceInspection}
                    // state.maintenanceInspections[i] = nMaintenanceInspection //Object.freeze(nMaintenanceInspection)
                }
            })

        },
        filterMaintenanceInspections(state, nMaintenanceInspections) {
            // let Ids = state.maintenanceInspections.map(u=> u.id)
            let nIds = nMaintenanceInspections.map(u => u.id)
            let removedIds = state.maintenanceInspections.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceInspections.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceInspections.splice(i, 1)
                }
            })
        },
        updateMaintenanceInspection(state, nMaintenanceInspection) {
            if (nMaintenanceInspection?.name) nMaintenanceInspection.name_ = nMaintenanceInspection.name.toLocaleLowerCase()
            let i = state.maintenanceInspections.findIndex(u => (u.id == nMaintenanceInspection.id))
            if (i < 0) {
                state.maintenanceInspections.push(nMaintenanceInspection) //(Object.freeze(nMaintenanceInspection))
            } else
            if (!state.maintenanceInspectionsFullLoad || state.maintenanceInspections[i][changedField] != nMaintenanceInspection[changedField]) {
                updateObjectByDiff(state.maintenanceInspections[i], nMaintenanceInspection)
                // delete nMaintenanceInspection.id
                // nMaintenanceInspection = {...state.maintenanceInspections[i], ...nMaintenanceInspection}
                // state.maintenanceInspections[i] = nMaintenanceInspection //Object.freeze(nMaintenanceInspection)
            }
        },
        deleteMaintenanceInspection(state, id) {
            let i = state.maintenanceInspections.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceInspections.splice(i, 1)
            }
        },

        updateMaintenanceInspectionForms(state, nMaintenanceInspectionForms) {
            if (!state.maintenanceInspectionForms.length) {
                nMaintenanceInspectionForms = nMaintenanceInspectionForms.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceInspectionForms.sort(sortByName)
                state.maintenanceInspectionForms = nMaintenanceInspectionForms
                return true
            }

            nMaintenanceInspectionForms.forEach(function (nMaintenanceInspectionForm) {
                if (nMaintenanceInspectionForm?.name) nMaintenanceInspectionForm.name_ = nMaintenanceInspectionForm.name.toLocaleLowerCase()
                let i = state.maintenanceInspectionForms.findIndex(u => (u.id == nMaintenanceInspectionForm.id))
                if (i < 0) {
                    state.maintenanceInspectionForms.push(nMaintenanceInspectionForm) //(Object.freeze(nMaintenanceInspectionForm))
                } else
                if (!state.maintenanceInspectionFormsFullLoad || state.maintenanceInspectionForms[i][changedField] != nMaintenanceInspectionForm[changedField]) {
                    updateObjectByDiff(state.maintenanceInspectionForms[i], nMaintenanceInspectionForm)
                    // delete nMaintenanceInspectionForm.id
                    // nMaintenanceInspectionForm = {...state.maintenanceInspectionForms[i], ...nMaintenanceInspectionForm}
                    // state.maintenanceInspectionForms[i] = nMaintenanceInspectionForm //Object.freeze(nMaintenanceInspectionForm)
                }
            })

        },

        clearMaintenanceInspections(state) {
            state.maintenanceInspections = []
            state.maintenanceInspectionsFullLoad = false
        },
    },
    getters: {
        isMaintenanceInspectionsFullLoad(state) {
            return state.maintenanceInspectionsFullLoad
        },
        isMaintenanceInspectionsLiteLoad(state) {
            return state.maintenanceInspectionsLiteLoad
        },
        getMaintenanceInspections(state) {
            return state.maintenanceInspections
        },
        sortedMaintenanceInspectionsIds(state) {
            let maintenanceInspections = state.maintenanceInspections
            maintenanceInspections.sort(sortByName)
            return maintenanceInspections.map(u => u.id)
        },
        getMaintenanceInspectionForms(state) {
            return state.maintenanceInspectionForms
        },
    }
}

<template>
    <button class="button button_view_icon button_theme_base mtb__-4"
            data-type="open-drop-menu"
            :disabled="disabled || !output.length"
    >
        <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__command" /></span> </span>
    </button>
    <div class="list__dropdown-menu dropdown-menu position_center-bottom">
        <div class="dropdown-menu__wrap">
            <ul class="dropdown-menu__list">
                <template v-for="(out, i) in output" :key="i">
                    <li class="dropdown-menu__item item item_not-has-icon"
                        @click="popupOutput(out.param , out.value , $t('{output} '+ out.text, {output: out.name}))"
                    >
                        <span class="dropdown-menu__item-name">
                            {{ $t('{output} '+ out.text , {output: out.name}) }}
                        </span>
                    </li>
                </template>
            </ul>
        </div>
    </div>
    <!-- popupOutput popup -->
    <custom-modal v-if="modalsWindows?.popupOutput" v-bind="modalsWindows?.popupOutput?.props">
        <p class="size-14">{{ $t('popup.Are you sure') }}</p>
    </custom-modal>
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {useToast} from "vue-toastification";
    import routeMixin from "@/mixins/routeMixin";

    export default {
        name: 'UnitsList_unit_menu_outputs',
        inject: ['modalsWindows'],
        mixins: [routeMixin],
        props: {
            unitId: [String, Number],
            disabled: Boolean,
        },
        data() {
            return {
                toast: useToast(),
            }
        },
        computed: {
            ...mapGetters([
                "getUnits"
            ]),
            unit() {
                return this.getUnits.find(u => u.id == this.unitId)
            },
            unit_outputs(){
                return this.unit?.outputs || []
            },
            output(){
                return this.unit_outputs.reduce((out,o) => {
                    if(o.name > '' && o.param > ''){
                        let inObject = !!out.find(ou => ou.name === o.name && ou.param === o.param)
                        if(!inObject) out.push({...o, value: 1, text: 'On'}, {...o, value: 0, text: 'Off'})
                    }
                    return out
                },[])
            },
        },
        methods: {
            ...mapActions([
                "sendUnitCmd",
            ]),
            ...mapMutations([
            ]),
            modalClose() {
                delete this.modalsWindows.popupOutput
            },
            popupOutput(output, value, title) {
                //create popup popupOutput
                this.modalsWindows.popupOutput = {
                    props: {
                        title,
                        buttons: [
                            {id: 'ok', fn: () => this.setUnitOutput(output, value)},
                            {id: 'cancel', fn: () => this.modalClose()},
                        ]
                    }
                }
            },
            setUnitOutput(output, value){
                let cmd = output+(value? " on" : " off")
                this.sendUnitCmd({ id: this.unitId, cmd })
                    .then((response) => {
                        //console.log(response)
                        if (response.data && !response.data.error) {
                            this.toast.success(this.$t("success!"))
                        } else
                        {
                            console.error(response.data);
                            let message = this.$t("error!")
                            if (response.data) {
                                message = response.data.message || response.data.errors || message
                            }
                            this.toast.error(message)
                        }
                    })
                    .catch((error) => {
                        // Error
                        if (error.response) {
                            console.error(error.response)
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        this.toast.error(this.$t("error!"))
                        //console.error(error.config);
                    })
                    .finally(() => {
                        this.modalClose()
                    })
            },
        },
    }
</script>
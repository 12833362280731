<template>
    <li class="list__sensors__body-item">
        <span class="list__sensors__body-item-icon">
            <SvgIcon name="unit-list-shock"/>
        </span>
        <span>{{ $t('Unit.sensors.shock') }}:
            <span class="list__sensors__body-item-value">
                {{shock}}
            </span>
        </span>
    </li>
</template>

<script>
    import {
        // mapActions,
        mapGetters,
    } from "vuex";

    export default {
        name: "UnitsList_unit_sensors_shock",
        props: {
            'unitId': [Array, String],
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsOnMap",
                "getUnitsLmsgs",
                "getAppUserUnits"
            ]),
            unitLmsg() {
                return this.getUnitsLmsgs.find(l => l.object_id == this.unitId)
            },
            shock() {
                return this.unitLmsg?.sensors?.shock?.text || '-'
            }
        },
        methods: {
            // ...mapActions([
            // ]),
        },
        updated: function() {
            // console.log(`unit status ${this.unitId} updated`);
        },
    }
</script>

<style scoped>

</style>
<template>
    <section
        class="section section-popup"
        :class="{'section-popup--open': showSectionPopupByRoute}"
        id="sectionPopupByRoute"
    >
    </section>
</template>

<script>
import {computed} from "vue";
import {mapActions, mapGetters} from "vuex";
import routeMixin from "@/mixins/routeMixin";

export default {
    name: "SectionPopupByRoute",
    mixins: [routeMixin],
    props:{},
    components:{
    },
    data() {
        return {
            popup: {
                title: '',
                subtitle: '',
            }
        }
    },
    provide(){
        return{
            popup: computed(() => this.popup),
        }
    },
    computed:{
        ...mapGetters([
        ]),
        showSectionPopupByRoute() {
            return this.routeMeta?.sectionPopupByRoute
        },
    },
    methods:{
        ...mapActions([
            "setSectionPopup"
        ]),

    },
    updated() {
        //console.log('SectionPopup updated', this.sectionPopup_component);
    },
    mounted() {
        // console.log('section_type_info', this)
    },
}
</script>

<style scoped>

</style>
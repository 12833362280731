<template>
    <section class="section section_type_content is_new-design" style="position: relative">
        <SectionPageHeader v-show="showHead" :title="page.title" :sub-title="page.subTitle"/>
        <div class="aside-menu_mobile-control" v-if="!isGuest">
            <button class="button button_view_icon button_view_icon-sm button_theme_primary mtb__-4" @click="toggleMobileMenu">
                <span class="icon">
                    <span class="icon__symbol">
                        <SvgIcon class="icon" :name="iconName" />
                    </span>
                </span>
            </button>
        </div>
        <section class="section__body body" :class="[pageLayout, pageClass, ...page.class, hideMenuLayers && 'hide-menu-layers']" >
            <section class="route-tabs" id="routeTabs">
                <template v-if="showRouteTabs">
                    <RouteTabs :list="routeTabs" v-if="routeTabs?.length"/>
                </template>
            </section>
            <section class="section section_type_map" id="sectionMap" v-if="$isPortalSite" v-show="showMap">
                <div class="toggle-layers-control" @click="toggleClassHideMenuLayers"></div>
                <SectionMap />
            </section>
            <router-view v-if="loadUser" v-show="(!isGuest || routeMeta.isGuest)" />
        </section>
        <SectionLoading class="section_type_loader-center section_type_loader-global" v-if="!loadUser" />
    </section>
</template>

<script>
    import {defineAsyncComponent,computed} from 'vue'
    import {mapGetters, mapMutations} from "vuex";
    import RouteTabs from "@/components/_base/RouteTabs.vue";
    import routeMixin from "@/mixins/routeMixin";
    // import SectionMap from "@/components/app/SectionMap.vue";
    // import SectionPageHeader from "@/components/app/SectionPageHeader.vue";

    const def_page = JSON.stringify({
        title: 'Main title',
        subTitle: 'Sub-title',
        class: [],
        grid: '',
    })

    export default {
        name: "SectionContent",
        mixins: [routeMixin],
        props: [],
        data() {
            return {
                hideMenuLayers: true,
                teleportMap: false,
                page: {
                    ...JSON.parse(def_page),
                },
            }
        },
        provide() {
            return {
                page: computed(() => this.page),
                fnHasPageClass: (val) => this.hasPageClass(val),
                fnAddPageClass: (val) => this.addPageClass(val),
                fnRemovePageClass: (val) => this.removePageClass(val),
                fnClearPageClass: () => this.clearPageClass(),
            }
        },
        components: {
            RouteTabs,
            'SectionPageHeader': defineAsyncComponent(() => import("@/components/app/SectionPageHeader.vue")),
            'SectionMap': defineAsyncComponent(() => import("@/components/app/SectionMap.vue")),
        },
        computed: {
            ...mapGetters([
                "apiToken",
                "isGuest",
                "getAppUserId",
                "getMobileMenuOpen",
            ]),
            loadUser(){
                return this.getAppUserId || this.routeMeta.isGuest
            },
            pageClass() {
                return this.routeMeta.pageClass || ''
            },
            pageLayout() {
                return this.routeMeta.pageLayout || 'block-position-r-1_c-1'
            },
            showMap() {
                return !!this.routeMeta.showMap && !this.routeMeta.hideMap
            },
            showHead() {
                return this.routeMeta.showHead
            },
            teleportMapMeta() {
                return this.routeMeta?.teleportMap
            },
            iconName(){
                return this.getMobileMenuOpen ? 'common__chevron_right_double' :  'common__chevron_left_double'
            },
            showRouteTabs() {
                return this.routeMeta?.showRouteTabs
            },
            routeTabs() {
                return this.routeMeta?.routeTabs
            },
        },
        watch: {
            teleportMapMeta: {
                handler: function () {
                    this.teleportMap = false
                    setTimeout(() => this.teleportMap = this.routeMeta?.teleportMap, 3000)
                    // this.$nextTick().then(() => this.teleportMap = this.routeMeta?.teleportMap)
                }
            },
            routeName: {
                handler: function (){
                    this.hideMenuLayers = true;
                    this.page = {
                        // ...this.page,
                        ...JSON.parse(def_page),
                        ...this.routeMeta.default,
                        default: this.routeMeta.default,
                    }
                },
                //deep: true,
            }
        },
        methods:{
            ...mapMutations([
                "setMobileMenuOpen",
            ]),
            toggleClassHideMenuLayers() {
                this.hideMenuLayers = !this.hideMenuLayers
            },
            hasPageClass(values){
                if(typeof values === 'string'){
                    return this.page.class.includes(values)
                } else {
                    console.error('values:' + values + 'not string')
                }
            },
            addPageClass(values){
                if(typeof values === 'string'){
                    values = [values]
                }
                if(Array.isArray(values)){
                    values = values.filter(cl => !this.page.class.includes(cl))
                    if(values.length) this.page.class.push(...values)
                }
            },
            removePageClass(values){
                if(typeof values === 'string'){
                    values = [values]
                }
                if(Array.isArray(values)){
                    let pageClass = this.page.class.filter(cl => !values.includes(cl))
                    if(this.page.class.length != pageClass.length){
                        this.page.class = [...pageClass]
                    }
                }
            },
            clearPageClass(){
                this.page.class = []
            },
            toggleMobileMenu(){
                this.setMobileMenuOpen(!this.getMobileMenuOpen)
            },
        },
        updated() {
            console.log('update content')
        },
    }
</script>
<style lang="scss">
.aside-menu_mobile-control{
    display: none;
}
.toggle-layers-control {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: white;
    position: absolute;
    top: 102px;
    right: 0.75rem;
    opacity: 0;
    z-index: 2000;
    cursor: pointer;

    &:hover {
        opacity: .3;
    }
}
.prometheus-portal {
    .toggle-layers-control {
        width: 25px;
        height: 25px;
        top: 72px;
    }
}
.hide-menu-layers {
    .leaflet-control-layers-list {
        display: none !important;
    }
}
.route-tabs {
    display: none;
}
</style>


// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = {'force[with_removed]': 1}//String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenanceExpensesFullLoad: false,
        maintenanceExpensesLiteLoad: false,
        maintenanceExpenses: [],
    },
    actions: {
        fetchMaintenanceExpenses/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceExpenses', time: Date.now() / 1000})

                const params = getApiProps('maintenance_expenses', args)
                this.$api.maintenanceexpenses.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceExpenses', inprogress: false})
                    });
            })
        },
        fetchMaintenanceExpensesAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceExpensesLiteLoad) {
                    return resolve(getters.getMaintenanceExpenses.length)
                }
                this.$api.init.getMaintenanceExpenses()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_expenses) {

                            commit('setMaintenanceExpenses', response.data.maintenance_expenses)
                            commit('setMaintenanceExpensesLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenanceExpensesAllInit', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceExpensesAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceExpensesLiteLoad) {
                    return resolve(getters.getMaintenanceExpenses.length)
                }
                dispatch('fetchMaintenanceExpenses', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceExpenses', data)
                        commit('setMaintenanceExpensesLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenanceExpensesAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceExpensesAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenanceExpensesAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenanceExpensesChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.getMaintenanceExpenses.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let items = getters.getMaintenanceExpenses.length
                    let pages = Math.ceil( items / pageSize)
                    if(pages > 8) {
                        pageSize = Math.ceil(items / consts.querySettings.maxPages)
                        pages = Math.ceil(items / pageSize)
                    }
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceExpenses', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceExpenses', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceExpenses', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenanceExpensesFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenanceExpensesAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenanceExpensesChanged', inprogress: false})
                })
        },
        fetchMaintenanceExpensesChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenanceExpensesFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenanceExpensesChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceExpenses', args)
                .then((data) => {
                    commit('updateMaintenanceExpenses', data)
                    return dispatch('fetchMaintenanceExpenses', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceExpenses', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenanceExpensesChanged', inprogress: false})
                });
        },

        saveMaintenanceExpanse({dispatch}, maintenanceExpanse) {
            let fn = (maintenanceExpanse.id) ? 'updateMaintenanceExpanse' : 'createMaintenanceExpanse'
            return dispatch(fn, maintenanceExpanse);
        },
        createMaintenanceExpanse({commit, dispatch}, maintenanceExpanse) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_expenses')
                this.$api.maintenanceexpenses.create(maintenanceExpanse, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceExpanse', response.data)
                            dispatch('fetchMaintenanceExpensesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceExpanse({commit, dispatch}, maintenanceExpanse) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_expenses')
                this.$api.maintenanceexpenses.update(maintenanceExpanse.id, maintenanceExpanse, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceExpanse', response.data)
                            dispatch('fetchMaintenanceExpensesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenanceExpanse({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_expenses')
                this.$api.maintenanceexpenses.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenanceExpanse', id)
                            else commit('updateMaintenanceExpanse', response.data)
                            dispatch('fetchMaintenanceExpensesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        removeMaintenanceExpanse({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_expenses')
                this.$api.maintenanceexpenses.removed(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceExpanse', response.data)
                            dispatch('fetchMaintenanceExpensesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreMaintenanceExpanse({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_expenses')
                this.$api.maintenanceexpenses.restore(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceExpanse', response.data)
                            dispatch('fetchMaintenanceExpensesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenanceExpensesFullLoad(state, FullLoad) {
            state.maintenanceExpensesFullLoad = state.maintenanceExpensesFullLoad || FullLoad
        },
        setMaintenanceExpensesLiteLoad(state, LitaLoad) {
            state.maintenanceExpensesLiteLoad = state.maintenanceExpensesLiteLoad || LitaLoad
        },

        setMaintenanceExpenses(state, nMaintenanceExpenses) {
            state.maintenanceExpenses = nMaintenanceExpenses
        },

        updateMaintenanceExpenses(state, nMaintenanceExpenses) {
            if (!state.maintenanceExpenses.length) {
                nMaintenanceExpenses = nMaintenanceExpenses.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceExpenses.sort(sortByName)
                state.maintenanceExpenses = nMaintenanceExpenses
                // const chunks = arraySplitIntoChunks(nMaintenanceExpenses)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceExpenses.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceExpenses.forEach(function (nMaintenanceExpanse) {
                if (nMaintenanceExpanse?.name) nMaintenanceExpanse.name_ = nMaintenanceExpanse.name.toLocaleLowerCase()
                let i = state.maintenanceExpenses.findIndex(u => (u.id == nMaintenanceExpanse.id))
                if (i < 0) {
                    state.maintenanceExpenses.push(nMaintenanceExpanse) //(Object.freeze(nMaintenanceExpanse))
                } else
                if (!state.maintenanceExpensesFullLoad || state.maintenanceExpenses[i][changedField] != nMaintenanceExpanse[changedField]) {
                    updateObjectByDiff(state.maintenanceExpenses[i], nMaintenanceExpanse)
                    // delete nMaintenanceExpanse.id
                    // nMaintenanceExpanse = {...state.maintenanceExpenses[i], ...nMaintenanceExpanse}
                    // state.maintenanceExpenses[i] = nMaintenanceExpanse //Object.freeze(nMaintenanceExpanse)
                }
            })

        },
        filterMaintenanceExpenses(state, nMaintenanceExpenses) {
            // let Ids = state.maintenanceExpenses.map(u=> u.id)
            let nIds = nMaintenanceExpenses.map(u => u.id)
            let removedIds = state.maintenanceExpenses.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceExpenses.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceExpenses.splice(i, 1)
                }
            })
        },
        updateMaintenanceExpanse(state, nMaintenanceExpanse) {
            if (nMaintenanceExpanse?.name) nMaintenanceExpanse.name_ = nMaintenanceExpanse.name.toLocaleLowerCase()
            let i = state.maintenanceExpenses.findIndex(u => (u.id == nMaintenanceExpanse.id))
            if (i < 0) {
                state.maintenanceExpenses.push(nMaintenanceExpanse) //(Object.freeze(nMaintenanceExpanse))
            } else
            if (!state.maintenanceExpensesFullLoad || state.maintenanceExpenses[i][changedField] != nMaintenanceExpanse[changedField]) {
                updateObjectByDiff(state.maintenanceExpenses[i], nMaintenanceExpanse)
                // delete nMaintenanceExpanse.id
                // nMaintenanceExpanse = {...state.maintenanceExpenses[i], ...nMaintenanceExpanse}
                // state.maintenanceExpenses[i] = nMaintenanceExpanse //Object.freeze(nMaintenanceExpanse)
            }
        },
        deleteMaintenanceExpanse(state, id) {
            let i = state.maintenanceExpenses.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceExpenses.splice(i, 1)
            }
        },

        clearMaintenanceExpenses(state) {
            state.maintenanceExpenses = []
            state.maintenanceExpensesFullLoad = false
        },
    },
    getters: {
        isMaintenanceExpensesFullLoad(state) {
            return state.maintenanceExpensesFullLoad
        },
        isMaintenanceExpensesLiteLoad(state) {
            return state.maintenanceExpensesLiteLoad
        },
        getMaintenanceExpenses(state) {
            return state.maintenanceExpenses
        },
        sortedMaintenanceExpensesIds(state) {
            let maintenanceExpenses = state.maintenanceExpenses
            maintenanceExpenses.sort(sortByName)
            return maintenanceExpenses.map(u => u.id)
        },
    }
}

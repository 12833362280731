// import Vue from 'vue'
import consts from "@/consts"
import {getApiProps} from "@/lib/lib";
// import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByHwTitle = function (a, b) {
    let cmp = collator.compare(a?.hw__title || '', b?.hw__title || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        shipmentTypesFullLoad: false,
        shipmentTypes: [
            {name: 'Documents', id: 'documents'},
            {name: 'Box', id: 'box'},
            {name: 'Container', id: 'container'},
            {name: 'Pallet', id: 'pallet'},
            {name: 'Food', id: 'food'},
        ],
    },
    actions: {
        fetchShipmentTypes/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipmentTypes', time: Date.now() / 1000})

                const params = getApiProps('hw_types', args)
                this.$api.shipmentstypes.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchShipmentTypes', inprogress: false})
                    });
            })
        },
        fetchShipmentTypesAll({dispatch, commit, getters}) {
            //dispatch('setLastCall', {name: 'fetchShipmentTypesAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchShipmentTypesChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    dispatch('fetchShipmentTypes', {lite: true})
                        .then((data) => {
                            commit('setShipmentTypes', data)
                            commit('setShipmentTypesFullLoad', true)
                            resolve(data.length)
                        })
                        .catch((error) => {
                            reject(error)
                            console.error(error);
                            setTimeout(() => {
                                dispatch('fetchShipmentTypesAll', {})
                            }, 60 * 1000)
                        })
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchShipmentTypesAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchShipmentTypesChanged', inprogress: false})
                });
        },
        fetchShipmentTypesChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isShipmentTypesFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchShipmentTypesChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchShipmentTypes', args)
                .then((data) => {
                    commit('updateShipmentTypes', data)
                    return dispatch('fetchShipmentTypes', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterShipmentTypes', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchShipmentTypesChanged', inprogress: false})
                });
        },
        saveShipmentType({dispatch}, shipmentType) {
            let fn = (shipmentType.id) ? 'updateShipmentType' : 'createShipmentType'
            return dispatch(fn, shipmentType);
        },
        createShipmentType({commit, dispatch}, shipmentType) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('hwtype')
                this.$api.shipmentstypes.create(shipmentType, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentType', response.data)
                            dispatch('fetchShipmentTypesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateShipmentType({commit, dispatch}, shipmentType) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('hwtype')
                this.$api.shipmentstypes.update(shipmentType.id, shipmentType, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentType', response.data)
                            dispatch('fetchShipmentTypesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteShipmentType({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                this.$api.shipmentstypes.delete(id)
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteShipmentType', id)
                            dispatch('fetchShipmentTypesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setShipmentTypesFullLoad(state, FullLoad) {
            state.shipmentTypesFullLoad = state.shipmentTypesFullLoad || FullLoad
        },
        setShipmentTypes(state, nShipmentTypes) {
            nShipmentTypes = nShipmentTypes.map(hwt => {
                if (hwt?.hw_title) hwt.hw__title = hwt.hw_title.toLocaleLowerCase()
                return Object.freeze(hwt)
            })
            nShipmentTypes.sort(sortByHwTitle)
            state.shipmentTypes = nShipmentTypes
        },
        updateShipmentTypes(state, nShipmentTypes) {
            if (!state.shipmentTypes.length) {
                nShipmentTypes = nShipmentTypes.map(hwt => {
                    if (hwt?.hw_title) hwt.hw__title = hwt.hw_title.toLocaleLowerCase()
                    return Object.freeze(hwt)
                })
                nShipmentTypes.sort(sortByHwTitle)
                state.shipmentTypes = nShipmentTypes
                return true
            }

            nShipmentTypes.forEach(function (nShipmentType) {
                let i = state.shipmentTypes.findIndex(hwt => (hwt.id == nShipmentType.id))
                if (i < 0) {
                    state.shipmentTypes.push(Object.freeze(nShipmentType))
                } else
                if (!state.shipmentTypesFullLoad || state.shipmentTypes[i][changedField] != nShipmentType[changedField]) {
                    // updateObjectByDiff(state.shipmentTypes[i], nShipmentType)
                    delete nShipmentType.id
                    state.shipmentTypes[i] = Object.freeze({...state.shipmentTypes[i], ...nShipmentType})
                }
            })
        },
        filterShipmentTypes(state, nShipmentTypes) {
            let nIds = nShipmentTypes.map(np => np.id)
            let removedIds = state.shipmentTypes.filter(hwt => !nIds.includes(hwt.id)).map(hwt => hwt.id)
            removedIds.forEach(removedId => {
                let i = state.shipmentTypes.findIndex(hwt => (hwt.id == removedId))
                if (i != -1) {
                    state.shipmentTypes.splice(i, 1)
                }
            })
        },
        updateShipmentType(state, nShipmentType) {
            let i = state.shipmentTypes.findIndex(hwt => (hwt.id == nShipmentType.id))
            if (i < 0) {
                state.shipmentTypes.push(Object.freeze(nShipmentType))
            } else
            if (!state.shipmentTypesFullLoad || state.shipmentTypes[i][changedField] != nShipmentType[changedField]) {
                // updateObjectByDiff(state.shipmentTypes[i], nShipmentType)
                delete nShipmentType.id
                state.shipmentTypes[i] = Object.freeze({...state.shipmentTypes[i], ...nShipmentType})
            }
        },
        deleteShipmentType(state, id) {
            let i = state.shipmentTypes.findIndex(t => (t.id == id))
            if (i != -1) {
                state.shipmentTypes.splice(i, 1)
            }
        },
        clearShipmentTypes(state) {
            state.shipmentTypes = [];
            state.shipmentTypesFullLoad = false
        },
    },
    getters: {
        isShipmentTypesFullLoad(state) {
            return state.shipmentTypesFullLoad
        },
        getShipmentTypes(state) {
            return state.shipmentTypes
        },

    }
}
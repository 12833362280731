<template>
    <div class="status-icon status-outputs-container" :class="[positionClass]" v-if="viewOutputs">
        <div class="status-outputs-status">
            <template v-for="(out, i) in outputs" :key="i">
                <div :class="[out.class, out.statusClass]" :title="out.view ? (out.name+':'+out.value) : ''">
                    <SvgIcon :name="out.icon" class="icon status-icon__icon"
                             :style="{
                                width: 16 +'px',
                                height: 8 +'px',
                                transform: 'rotate(-90deg) scale(2)',
                                transformOrigin: 'center'
                             }"
                             v-if="out.icon"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "UnitsList_unit_outputs_status",
    props: {
        'unitId': [Number, String],
        'position':{
            type: String,
            default: () => { return 'bl' }
        }
    },
    components: {
    },
    data() {
        return {
            statuses: {
                online: 'ok',
                offline: 'error',
                standby: 'warning',
            },
            optionsByValue:{
                'on': {
                    icon: '',
                    class: 'status-outputs-command',
                    value: this.$i18n.t('On'),
                    statusClass: 'status-outputs-command-on',
                },
                'off':{
                    icon: '',
                    class: 'status-outputs-command',
                    value: this.$i18n.t('Off'),
                    statusClass: 'status-outputs-command-off',
                },
                'null':{
                    icon: '',
                    class: 'status-outputs-command',
                    value: '-',
                    statusClass: '',
                }
            }
        }
    },
    computed: {
        ...mapGetters([
            "getUnits",
            "getUnitsOutputsById",
        ]),
        unit() {
            return this.getUnits.find(u => u.id == this.unitId)
        },
        outputs() {
            let outputs = this.getUnitsOutputsById[this.unitId] || []
            return outputs.map((out, i) => {
                let options = this.optionsByValue[out.value];
                return {
                    icon: options.icon,
                    class: options.class + '-' +i,
                    statusClass: options.statusClass,
                    value: options.value,
                    name: out.name,
                    view: out.view
                }
            });
        },
        viewOutputs() {
            return !!this.outputs.filter(out => out.view).length
        },
        positionClass(){
            return 'status-icon__position-' + this.position
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>
// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenanceHistorysFullLoad: false,
        maintenanceHistorysLiteLoad: false,
        maintenanceHistorys: [],
        maintenanceHistoryAlgorithms: {},
        maintenanceHistoryStatuses: {},
    },
    actions: {
        fetchMaintenanceHistorys/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceHistorys', time: Date.now() / 1000})

                const params = getApiProps('maintenance_historys', args)
                this.$api.maintenancehistory.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceHistorys', inprogress: false})
                    });
            })
        },
        fetchMaintenanceHistorysAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceHistorysLiteLoad) {
                    return resolve(getters.getMaintenanceHistorys.length)
                }
                this.$api.init.getMaintenanceHistorys()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_history) {

                            commit('setMaintenanceHistorys', response.data.maintenance_history)
                            commit('setMaintenanceHistorysLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenanceHistorysAllInit', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceHistorysAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceHistorysLiteLoad) {
                    return resolve(getters.getMaintenanceHistorys.length)
                }
                dispatch('fetchMaintenanceHistorys', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceHistorys', data)
                        commit('setMaintenanceHistorysLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenanceHistorysAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceHistorysAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenanceHistorysAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenanceHistorysChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.getMaintenanceHistorys.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let items = getters.getMaintenanceHistorys.length
                    let pages = Math.ceil( items / pageSize)
                    if(pages > 8) {
                        pageSize = Math.ceil(items / consts.querySettings.maxPages)
                        pages = Math.ceil(items / pageSize)
                    }
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceHistorys', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceHistorys', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceHistorys', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenanceHistorysFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenanceHistorysAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenanceHistorysChanged', inprogress: false})
                })
        },
        fetchMaintenanceHistorysChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenanceHistorysFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenanceHistorysChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceHistorys', args)
                .then((data) => {
                    commit('updateMaintenanceHistorys', data)
                    return dispatch('fetchMaintenanceHistorys', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceHistorys', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenanceHistorysChanged', inprogress: false})
                });
        },

        fetchMaintenanceHistoryAlgorithms({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceHistoryAlgorithms', time: Date.now() / 1000})

                this.$api.maintenancehistory.getHistoryAlgorithms()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceHistoryAlgorithms', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceHistoryAlgorithms', inprogress: false})
                    });
            })
        },
        fetchMaintenanceHistoryStatuses({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceHistoryStatuses', time: Date.now() / 1000})

                this.$api.maintenancehistory.getHistoryStatuses()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceHistoryStatuses', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceHistoryStatuses', inprogress: false})
                    });
            })
        },

        saveMaintenanceHistory({dispatch}, maintenanceHistory) {
            let fn = (maintenanceHistory.id) ? 'updateMaintenanceHistory' : 'createMaintenanceHistory'
            return dispatch(fn, maintenanceHistory);
        },
        createMaintenanceHistory({commit, dispatch}, maintenanceHistory) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_historys')
                this.$api.maintenancehistory.create(maintenanceHistory, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceHistory', response.data)
                            dispatch('fetchMaintenanceHistorysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceHistory({commit, dispatch}, maintenanceHistory) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_historys')
                this.$api.maintenancehistory.update(maintenanceHistory.id, maintenanceHistory, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceHistory', response.data)
                            dispatch('fetchMaintenanceHistorysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenanceHistory({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_historys')
                this.$api.maintenancehistory.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenanceHistory', id)
                            else commit('updateMaintenanceHistory', response.data)
                            dispatch('fetchMaintenanceHistorysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        createMaintenanceService({dispatch}, service) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_service')
                this.$api.maintenancehistory.servicesCreate(service, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            dispatch('fetchMaintenanceHistorysChanged')
                            dispatch('fetchMaintenanceChanged')
                            dispatch('fetchMaintenanceNotifications')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceService({dispatch}, service) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_service')
                this.$api.maintenancehistory.servicesUpdate(service, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            dispatch('fetchMaintenanceHistorysChanged')
                            dispatch('fetchMaintenanceChanged')
                            dispatch('fetchMaintenanceNotifications')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenanceHistorysFullLoad(state, FullLoad) {
            state.maintenanceHistorysFullLoad = state.maintenanceHistorysFullLoad || FullLoad
        },
        setMaintenanceHistorysLiteLoad(state, LitaLoad) {
            state.maintenanceHistorysLiteLoad = state.maintenanceHistorysLiteLoad || LitaLoad
        },

        updateMaintenanceHistoryAlgorithms(state, nMaintenanceHistoryAlgorithms) {
            if (!state.maintenanceHistoryAlgorithms.length) {
                state.maintenanceHistoryAlgorithms = nMaintenanceHistoryAlgorithms
                return true
            }

            nMaintenanceHistoryAlgorithms.forEach(function (nMaintenanceHistoryAlgorithm) {
                let i = state.maintenanceHistoryAlgorithms.findIndex(u => (u == nMaintenanceHistoryAlgorithm))
                if (i < 0) {
                    state.maintenanceHistoryAlgorithms.push(nMaintenanceHistoryAlgorithm) //(Object.freeze(nMaintenanceIssueStatus))
                } else
                if (!state.maintenanceIssuesFullLoad || state.maintenanceHistoryAlgorithms[i][changedField] != nMaintenanceHistoryAlgorithm[changedField]) {
                    updateObjectByDiff(state.maintenanceHistoryAlgorithms[i], nMaintenanceHistoryAlgorithm)
                    // delete nMaintenanceIssueStatus.id
                    // nMaintenanceIssueStatus = {...state.maintenanceIssues[i], ...nMaintenanceIssueStatus}
                    // state.maintenanceIssues[i] = nMaintenanceIssueStatus //Object.freeze(nMaintenanceIssueStatus)
                }
            })

        },
        updateMaintenanceHistoryStatuses(state, nMaintenanceHistoryStatuses) {
            if (!state.maintenanceHistoryStatuses.length) {
                state.maintenanceHistoryStatuses = nMaintenanceHistoryStatuses
                return true
            }

            nMaintenanceHistoryStatuses.forEach(function (nMaintenanceHistoryStatus) {
                let i = state.maintenanceHistoryStatuses.findIndex(u => (u == nMaintenanceHistoryStatus))
                if (i < 0) {
                    state.maintenanceHistoryStatuses.push(nMaintenanceHistoryStatus) //(Object.freeze(nMaintenanceIssueStatus))
                } else
                if (!state.maintenanceIssuesFullLoad || state.maintenanceHistoryStatuses[i][changedField] != nMaintenanceHistoryStatus[changedField]) {
                    updateObjectByDiff(state.maintenanceHistoryStatuses[i], nMaintenanceHistoryStatus)
                    // delete nMaintenanceIssueStatus.id
                    // nMaintenanceIssueStatus = {...state.maintenanceIssues[i], ...nMaintenanceIssueStatus}
                    // state.maintenanceIssues[i] = nMaintenanceIssueStatus //Object.freeze(nMaintenanceIssueStatus)
                }
            })

        },

        setMaintenanceHistorys(state, nMaintenanceHistorys) {
            state.maintenanceHistorys = nMaintenanceHistorys
        },

        updateMaintenanceHistorys(state, nMaintenanceHistorys) {
            if (!state.maintenanceHistorys.length) {
                nMaintenanceHistorys = nMaintenanceHistorys.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceHistorys.sort(sortByName)
                state.maintenanceHistorys = nMaintenanceHistorys
                // const chunks = arraySplitIntoChunks(nMaintenanceHistorys)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceHistorys.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceHistorys.forEach(function (nMaintenanceHistory) {
                if (nMaintenanceHistory?.name) nMaintenanceHistory.name_ = nMaintenanceHistory.name.toLocaleLowerCase()
                let i = state.maintenanceHistorys.findIndex(u => (u.id == nMaintenanceHistory.id))
                if (i < 0) {
                    state.maintenanceHistorys.push(nMaintenanceHistory) //(Object.freeze(nMaintenanceHistory))
                } else
                if (!state.maintenanceHistorysFullLoad || state.maintenanceHistorys[i][changedField] != nMaintenanceHistory[changedField]) {
                    updateObjectByDiff(state.maintenanceHistorys[i], nMaintenanceHistory)
                    // delete nMaintenanceHistory.id
                    // nMaintenanceHistory = {...state.maintenanceHistorys[i], ...nMaintenanceHistory}
                    // state.maintenanceHistorys[i] = nMaintenanceHistory //Object.freeze(nMaintenanceHistory)
                }
            })

        },
        filterMaintenanceHistorys(state, nMaintenanceHistorys) {
            // let Ids = state.maintenanceHistorys.map(u=> u.id)
            let nIds = nMaintenanceHistorys.map(u => u.id)
            let removedIds = state.maintenanceHistorys.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceHistorys.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceHistorys.splice(i, 1)
                }
            })
        },
        updateMaintenanceHistory(state, nMaintenanceHistory) {
            if (nMaintenanceHistory?.name) nMaintenanceHistory.name_ = nMaintenanceHistory.name.toLocaleLowerCase()
            let i = state.maintenanceHistorys.findIndex(u => (u.id == nMaintenanceHistory.id))
            if (i < 0) {
                state.maintenanceHistorys.push(nMaintenanceHistory) //(Object.freeze(nMaintenanceHistory))
            } else
            if (!state.maintenanceHistorysFullLoad || state.maintenanceHistorys[i][changedField] != nMaintenanceHistory[changedField]) {
                updateObjectByDiff(state.maintenanceHistorys[i], nMaintenanceHistory)
                // delete nMaintenanceHistory.id
                // nMaintenanceHistory = {...state.maintenanceHistorys[i], ...nMaintenanceHistory}
                // state.maintenanceHistorys[i] = nMaintenanceHistory //Object.freeze(nMaintenanceHistory)
            }
        },
        deleteMaintenanceHistory(state, id) {
            let i = state.maintenanceHistorys.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceHistorys.splice(i, 1)
            }
        },

        clearMaintenanceHistorys(state) {
            state.maintenanceHistorys = []
            state.maintenanceHistorysFullLoad = false
        },
    },
    getters: {
        isMaintenanceHistorysFullLoad(state) {
            return state.maintenanceHistorysFullLoad
        },
        isMaintenanceHistorysLiteLoad(state) {
            return state.maintenanceHistorysLiteLoad
        },
        getMaintenanceHistorys(state) {
            return state.maintenanceHistorys
        },
        sortedMaintenanceHistorysIds(state) {
            let maintenanceHistorys = state.maintenanceHistorys
            maintenanceHistorys.sort(sortByName)
            return maintenanceHistorys.map(u => u.id)
        },
        getMaintenanceHistoryAlgorithms(state) {
            return state.maintenanceHistoryAlgorithms
        },
        getMaintenanceHistoryStatuses(state) {
            return state.maintenanceHistoryStatuses
        }
    }
}

import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

export default {
    state: {
        idlings_index: [],
        idlings: [],
    },
    actions: {
        fetchIdlings4Date({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                args = {unitId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                let isToday = (args.date == getters.getDateToday)
                if (!getters.apiToken || !args.unitId || !args.date) {
                    return reject(false)
                }

                // if today come throw 00h - it doesn't work (index ???)
                // let idling = getters.getIdlings.find(t => (t.unit_id == args.unitId && t.date == args.date))
                // let record = getters.getIdlingsIndex.find(i => i.date == args.date &&  i.unit_id == args.unitId  && (!i.timeout || i.timeout > (Date.now() / 1000)))
                // if(idling && args.date !== getters.getDateToday) {
                //     return resolve(true)
                // }

                dispatch('setLastCall', {name: 'fetchIdlings4Date', time: Date.now() / 1000})
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastIdlings', time: Date.now() / 1000})
                }

                let params = {withAddress: 1, ...args.params}
                this.$api.idlings.get4Date(args.unitId, args.date, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeIdlings', response.data)
                            commit('setIdlingsIndex', {
                                ...args,
                                count: response.data.length,
                                timeout: isToday ? ((Date.now() / 1000) + consts.indexTimeout.idlings) : false
                            })
                            if (!args.unitId && response.data.length) {
                                // debugger
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchIdlings4Date', inprogress: false})
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastIdlings', inprogress: false})
                        }
                    });
            })
        },
        fetchIdlings4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.unitId || !args.from || !args.to) {
                    return reject(false)
                }
                let idling = getters.getIdlings.find(t => (t.unit_id == args.unitId && t.time_start?.utc == args.from && t.time_end?.utc == args.to))
                if (idling) {
                    return resolve(true)
                }
                dispatch('setLastCall', {name: 'fetchIdlings4Date', time: Date.now() / 1000})
                /* getters.getTimeToday+24h and args.from/to intersect*/
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastIdlings', time: Date.now() / 1000})
                }

                let params = {withAddress: 1, ...args.params}
                this.$api.idlings.get4Period(args.unitId, args.from, args.to, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeIdlings', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchIdlings4Date', inprogress: false})
                        /* getters.getTimeToday+24h and args.from/to intersect*/
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastIdlings', inprogress: false})
                        }
                    });
            })
        },
        reloadIdlings({commit, dispatch}, args) {
            commit('clearIdlings')
            return dispatch('fetchIdlings4Date', {date: 'today', ...args})
        },

        //sayHello() {}
    },
    mutations: {
        removeIdlingsIndex(state) {
            state.idlings_index =
                state.idlings_index.filter(i => !i.timeout || i.timeout > (Date.now() / 1000))
        },
        setIdlingsIndex(state, params) {
            let i = state.idlings_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if (i < 0) {
                state.idlings_index.push(Object.freeze({...params}))
            } else {
                state.idlings_index[i] = Object.freeze({...state.idlings_index[i], ...params})
            }
        },
        storeIdlings(state, nIdlings) {
            nIdlings = nIdlings
                .filter(p => !state.idlings.find(_p => {
                    return _p.unit_id == p.unit_id
                        && _p.time_start?.utc == p.time_start?.utc
                    // && _p.time_end?.utc == p.time_end?.utc
                }))
                .map(p => Object.freeze(p))

            // if(nIdlings.length) state.idlings.push(...nIdlings)
            if (!nIdlings.length) return false
            if (!state.idlings.length) {
                state.idlings = nIdlings
                return true
            }
            state.idlings.push(...nIdlings)

            //vue recommended
            // const chunks = arraySplitIntoChunks(nIdlings)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.idlings.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearIdlings(state) {
            state.idlings = [];
            state.idlings_index = [];
        },
    },
    getters: {
        getIdlingsIndex(state) {
            return state.idlings_index
        },
        getIdlings(state) {
            return state.idlings
        },
    }
}

// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = {'force[with_removed]': 1}//String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenanceVendorsFullLoad: false,
        maintenanceVendorsLiteLoad: false,
        maintenanceVendors: [],
    },
    actions: {
        fetchMaintenanceVendors/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceVendors', time: Date.now() / 1000})

                const params = getApiProps('maintenance_vendors', args)
                this.$api.maintenancevendors.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceVendors', inprogress: false})
                    });
            })
        },
        fetchMaintenanceVendorsAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceVendorsLiteLoad) {
                    return resolve(getters.getMaintenanceVendors.length)
                }
                this.$api.init.getMaintenanceVendors()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_vendors) {
                            commit('setMaintenanceVendors', response.data.maintenance_vendors)
                            commit('setMaintenanceVendorsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenanceVendorsAllInit', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceVendorsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceVendorsLiteLoad) {
                    return resolve(getters.getMaintenanceVendors.length)
                }
                dispatch('fetchMaintenanceVendors', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceVendors', data)
                        commit('setMaintenanceVendorsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenanceVendorsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceVendorsAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenanceVendorsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenanceVendorsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.getMaintenanceVendors.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let items = getters.getMaintenanceVendors.length
                    let pages = Math.ceil( items / pageSize)
                    if(pages > 8) {
                        pageSize = Math.ceil(items / consts.querySettings.maxPages)
                        pages = Math.ceil(items / pageSize)
                    }
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceVendors', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceVendors', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceVendors', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenanceVendorsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenanceVendorsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenanceVendorsChanged', inprogress: false})
                })
        },
        fetchMaintenanceVendorsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenanceVendorsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenanceVendorsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceVendors', args)
                .then((data) => {
                    commit('updateMaintenanceVendors', data)
                    return dispatch('fetchMaintenanceVendors', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceVendors', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenanceVendorsChanged', inprogress: false})
                });
        },

        saveMaintenanceVendor({dispatch}, maintenanceVendor) {
            let fn = (maintenanceVendor.id) ? 'updateMaintenanceVendor' : 'createMaintenanceVendor'
            return dispatch(fn, maintenanceVendor);
        },
        createMaintenanceVendor({commit, dispatch}, maintenanceVendor) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_vendors')
                this.$api.maintenancevendors.create(maintenanceVendor, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceVendor', response.data)
                            dispatch('fetchMaintenanceVendorsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceVendor({commit, dispatch}, maintenanceVendor) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_vendors')
                this.$api.maintenancevendors.update(maintenanceVendor.id, maintenanceVendor, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceVendor', response.data)
                            dispatch('fetchMaintenanceVendorsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenanceVendor({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_vendors')
                this.$api.maintenancevendors.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenanceVendor', id)
                            else commit('updateMaintenanceVendor', response.data)
                            dispatch('fetchMaintenanceVendorsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        removedMaintenanceVendor({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_vendors')
                this.$api.maintenancevendors.removed(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceVendor', response.data)
                            dispatch('fetchMaintenanceVendorsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreMaintenanceVendor({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_vendors')
                this.$api.maintenancevendors.restore(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceVendor', response.data)
                            dispatch('fetchMaintenanceVendorsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenanceVendorsFullLoad(state, FullLoad) {
            state.maintenanceVendorsFullLoad = state.maintenanceVendorsFullLoad || FullLoad
        },
        setMaintenanceVendorsLiteLoad(state, LitaLoad) {
            state.maintenanceVendorsLiteLoad = state.maintenanceVendorsLiteLoad || LitaLoad
        },

        setMaintenanceVendors(state, nMaintenanceVendors) {
            state.maintenanceVendors = nMaintenanceVendors
        },

        updateMaintenanceVendors(state, nMaintenanceVendors) {
            if (!state.maintenanceVendors.length) {
                nMaintenanceVendors = nMaintenanceVendors.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceVendors.sort(sortByName)
                state.maintenanceVendors = nMaintenanceVendors
                // const chunks = arraySplitIntoChunks(nMaintenanceVendors)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceVendors.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceVendors.forEach(function (nMaintenanceVendor) {
                if (nMaintenanceVendor?.name) nMaintenanceVendor.name_ = nMaintenanceVendor.name.toLocaleLowerCase()
                let i = state.maintenanceVendors.findIndex(u => (u.id == nMaintenanceVendor.id))
                if (i < 0) {
                    state.maintenanceVendors.push(nMaintenanceVendor) //(Object.freeze(nMaintenanceVendor))
                } else
                if (!state.maintenanceVendorsFullLoad || state.maintenanceVendors[i][changedField] != nMaintenanceVendor[changedField]) {
                    updateObjectByDiff(state.maintenanceVendors[i], nMaintenanceVendor)
                    // delete nMaintenanceVendor.id
                    // nMaintenanceVendor = {...state.maintenanceVendors[i], ...nMaintenanceVendor}
                    // state.maintenanceVendors[i] = nMaintenanceVendor //Object.freeze(nMaintenanceVendor)
                }
            })

        },
        filterMaintenanceVendors(state, nMaintenanceVendors) {
            // let Ids = state.maintenanceVendors.map(u=> u.id)
            let nIds = nMaintenanceVendors.map(u => u.id)
            let removedIds = state.maintenanceVendors.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceVendors.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceVendors.splice(i, 1)
                }
            })
        },
        updateMaintenanceVendor(state, nMaintenanceVendor) {
            if (nMaintenanceVendor?.name) nMaintenanceVendor.name_ = nMaintenanceVendor.name.toLocaleLowerCase()
            let i = state.maintenanceVendors.findIndex(u => (u.id == nMaintenanceVendor.id))
            if (i < 0) {
                state.maintenanceVendors.push(nMaintenanceVendor) //(Object.freeze(nMaintenanceVendor))
            } else
            if (!state.maintenanceVendorsFullLoad || state.maintenanceVendors[i][changedField] != nMaintenanceVendor[changedField]) {
                updateObjectByDiff(state.maintenanceVendors[i], nMaintenanceVendor)
                // delete nMaintenanceVendor.id
                // nMaintenanceVendor = {...state.maintenanceVendors[i], ...nMaintenanceVendor}
                // state.maintenanceVendors[i] = nMaintenanceVendor //Object.freeze(nMaintenanceVendor)
            }
        },
        deleteMaintenanceVendor(state, id) {
            let i = state.maintenanceVendors.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceVendors.splice(i, 1)
            }
        },

        clearMaintenanceVendors(state) {
            state.maintenanceVendors = []
            state.maintenanceVendorsFullLoad = false
        },
    },
    getters: {
        isMaintenanceVendorsFullLoad(state) {
            return state.maintenanceVendorsFullLoad
        },
        isMaintenanceVendorsLiteLoad(state) {
            return state.maintenanceVendorsLiteLoad
        },
        getMaintenanceVendors(state) {
            return state.maintenanceVendors
        },
        sortedMaintenanceVendorsIds(state) {
            let maintenanceVendors = state.maintenanceVendors
            maintenanceVendors.sort(sortByName)
            return maintenanceVendors.map(u => u.id)
        },
    }
}
